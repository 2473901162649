import React, { useEffect, useState } from "react";
// import Header from "../Header";
// import { Grid, Typography } from "@material-ui/core";
// import { ExamTab, ExamTabs } from "../ExamTypeSelectNav";
// import { useStyles1 } from "./styles";
// import ZoomAttendance from "./ZoomAttendance/index";
// import ClassAttendance from "./ClassAttendance";
// // import { getAttendance } from "../../services/attendanceService";
import CalendarComponent from "./AttComponent";

export default function FullWidthGrid() {
  return (
    <>
      <CalendarComponent />
    </>
  );
}
