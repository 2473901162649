// MainComponent.js
import React from "react";
import store from "store";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import useStyles from "./styles"; // Adjust the path as necessary
import Avatar from "./avatar"; // Adjust the path as necessary
import FeeDueAmount from "./FeeDueAmount"; // Adjust the path as necessary
import CategoryList from "./CategoryList"; // Adjust the path as necessary
import { withRouter } from "react-router-dom";

const MainComponent = ({ history }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:600px)");
  const { accountType = "" } = store.get("user") || {};

  const handleCatClick = catVal => {
    if (catVal === "exams") {
      console.log("View Exams clicked");
      history.push("/home");
    }
    if (catVal === "analysis") {
      console.log("Analysis clicked");
      history.push("/exam-analysis");
    }
    if (catVal === "classRoom") {
      console.log("My Classroom clicked");
      history.push("/classroom");
    }
    if (catVal === "attendance") {
      console.log("Attendance clicked");
      history.push("/attendance");
    }
    if (catVal === "feeDetails") {
      console.log("Fee Details clicked");
      history.push("/fee-details");
    }
    if (catVal === "profile") {
      console.log("View My Profile clicked");
      history.push("/view-profile");
    }
    if (catVal === "progressCard") {
      console.log("Progress Card clicked");
      history.push("/progress-card");
    }
    if (catVal === "liveClasses") {
      history.push("/video-classes");
    }
    if (catVal === "viewLibrary") {
      history.push("/assignment");
    }
    if (catVal === "PreviousClasses") {
      history.push("/previous-classes");
    }
    if (catVal === "topicWiseAnalysis") {
      history.push("/topicWiseAnalysis");
    }
    if(catVal==="pocketMoney")
    {
      history.push("/pocket-money")
    }
  };

  const handleClick = () => {
    history.push("/fee-details");
  };

  return (
    <>
      {isMobile ? <Avatar /> : null}

      <div style={{ margin: "16px" }}>
        <FeeDueAmount handleClick={handleClick} />
        &nbsp;
        <CategoryList handleCatClick={handleCatClick} />
      </div>
    </>
  );
};

export default withRouter(MainComponent);
