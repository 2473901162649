import React from "react";
import moment from "moment";
// mui
import Accordion from "@material-ui/core/Accordion";
import Typography from "@material-ui/core/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandIcon from "@material-ui/icons/ExpandMore";
// components
import ContentTypeA from "./contentTypeA";
// styles
import feeDetailsStyles from "./styles";
// utils
import numberWithCommas from "../../utils/numberWithCommas";

// const formatter = new Intl.NumberFormat("en-IN", {
//   style: "currency",
//   currency: "INR",
//   maximumFractionDigits: 0,
// });

const formatter = {
  format: (fig) => `₹${numberWithCommas(fig)}`
};

const TxnCard = ({ txn }) => {
  const classes = feeDetailsStyles();
  const { receiptDate, amount, fees = {}, status, receiptNo, txnId } = txn;
  return (
    <div className={classes.txnContainer}>
      <Accordion
        style={{ borderRadius: "16px" }}
        className={`${classes.accordion}`}
        defaultExpanded
      >
        <AccordionSummary expandIcon={<ExpandIcon />}>
          <div className={classes.descTitleContainer}>
            <Typography
              variant="caption"
              className={classes.subHeading}
              color="textPrimary"
            >
              {receiptNo || txnId}<br />
              {/* {`${moment(receiptDate).format("DD.MM.YYYY")}`} */}
            </Typography>
            <Typography
              variant="caption"
              className={classes.subHeading}
              color="secondary"
            >
              {formatter.format(amount)}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ width: "100%" }}>
            {Object.keys(fees).map((item, i) => {
              return (
                <div className={classes.descTitleContainer} key={`a-${i}`}>
                  <Typography
                    variant="caption"
                    color="textPrimary"
                    className={classes.descFeeName}
                  >
                    {item}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="textPrimary"
                    className={classes.descFeeValue}
                  >
                    {formatter.format(fees[item] ? fees[item] : "")}
                  </Typography>
                </div>
              );
            })}
            <div className={classes.txnDescContainer}>
              <Typography variant="h6" className={classes.txnDetailsText}>
                Transaction Details
              </Typography>
              <ContentTypeA
                itemA={{
                  name: "Transaction Id",
                  value: `${txnId}`,
                  valueColor: "primary",
                }}
                itemB={{
                  name: "Receipt No",
                  value: `${receiptNo || txnId} `,
                  valueColor: "primary",
                }}
              />
              <ContentTypeA
                itemA={{
                  name: "Date & Time",
                  value: `${moment(receiptDate).format("DD.MM.YYYY hh:mm a")}`,
                  valueColor: "primary",
                }}
                itemB={{
                  name: "Status",
                  value: `${status === "Ok" ? "SUCCESS" : status} `,
                  valueColor: "success",
                }}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default TxnCard;
