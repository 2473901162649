import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    borderRadius: "10px"
  },
  legend: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px",
    gap: "16px",
    width: "100%",
    height: "75px",
    flex: "none",
    order: 2,
    flexGrow: 0
    // margin: "20px"
  },
  ledgerItem: {
    fontFamily: "Quicksand",
    justifyContent: "center",
    alignItems: "center",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "20px",
    color: "#2A2A2A",
    flex: "none",
    order: 0,
    flexGrow: 0
  },
  title: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#2A2A2A"
  }
}));
const DonutChart = ({ data, todayRes, monthlabel, allMonthDatesLength }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const processedData = Object.values(data).reduce((acc, status) => {
    acc[status] = (acc[status] || 0) + 1;
    return acc;
  }, {});

  const chartData = Object.keys(processedData).map(key => ({
    name: key === "NA" ? "Not Taken" : key,
    value: processedData[key]
  }));

  // Define colors for each status
  const colors = {
    Absent: "url(#gradientAbsent)",
    Present: "url(#gradientPresent)",
    "Not Taken": "url(#gradientNA)"
  };
  const borderColors = {
    Absent: "#BF5A30", // Example border color for "Absent"
    Present: "#2D9CDB", // Example border color for "Present"
    "Not Taken": "#4B6A9B" // Example border color for "NA"
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid
          item
          xs={6}
          container
          justifyContent="flex-start"
          direction="column"
        >
          <Typography className={classes.title}>Monthly Report</Typography>
          <Typography className={classes.legendItem}>{monthlabel}</Typography>
        </Grid>
        <Grid
          item
          xs={6}
          container
          justifyContent="flex-end"
          direction="column"
          alignContent="flex-end"
          alignItems="flex-end"
        >
          <Typography className={classes.legendItem}>Total Days</Typography>
          <Typography className={classes.title}>
            {allMonthDatesLength} &nbsp;Days&nbsp;
          </Typography>
        </Grid>
      </Grid>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <PieChart width={300} height={300}>
          <defs>
            {/* Define gradients */}
            <linearGradient id="gradientAbsent" x1="0" y1="0" x2="1" y2="1">
              <stop offset="52.26%" stopColor="#FC5132" stopOpacity={1} />
              <stop
                offset="60.55%"
                stopColor="rgba(252, 81, 50, 0.92)"
                stopOpacity={1}
              />
              <stop offset="68.21%" stopColor="#FC5132" stopOpacity={1} />
            </linearGradient>
            <linearGradient id="gradientPresent" x1="0" y1="0" x2="1" y2="1">
              <stop offset="0%" stopColor="#4ADEE8" stopOpacity={1} />
              <stop
                offset="54.52%"
                stopColor="rgba(74, 222, 232, 0.92)"
                stopOpacity={1}
              />
              <stop offset="104.69%" stopColor="#4ADEE8" stopOpacity={1} />
            </linearGradient>
            <linearGradient id="gradientNA" x1="0" y1="0" x2="1" y2="1">
              <stop offset="0%" stopColor="#3358B8" stopOpacity={1} />
              <stop
                offset="52%"
                stopColor="rgba(85, 133, 255, 0.92)"
                stopOpacity={1}
              />
              <stop offset="100%" stopColor="#3358B8" stopOpacity={1} />
            </linearGradient>
          </defs>

          <Pie
            data={chartData}
            dataKey="value"
            label={false} // Remove labels
            strokeWidth={2} // Width of the border
            stroke="none"
            legendType="circle"
            startAngle={180}
            endAngle={-180}
            cornerRadius={100}
            innerRadius={60}
            outerRadius={80}
            paddingAngle={-10}
            labelLine={false}
            isAnimationActive
          >
            {chartData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[entry.name]}
                // stroke={borderColors[entry.name]} // Border color
                strokeWidth={2} // Border thickness
              />
            ))}
          </Pie>
          <Tooltip />
          <Legend
            layout="horizontal"
            verticalAlign="top"
            align="center"
            wrapperStyle={{
              position: "relative",
              top: -290,
              fontSize: "10px",
              fontWeight: "bold",
              color: "#2A2A2A"
            }} // Adjust as needed
          />
        </PieChart>
      </div>
      <div className={classes.legend}>
        <div className={classes.legendItem}>
          <span style={{ backgroundColor: "#00c853", fontSize: "20px" }} />{" "}
          Present
          <br />
          <b
            style={{
              color: "#46AB87",
              fontSize: "20px",
              padding: "5px",
              alignContent: "center"
            }}
          >
            {processedData.Present || 0}
          </b>
        </div>
        <div className={classes.legendItem}>
          <span style={{ backgroundColor: "#d50000", fontSize: "20px" }} />{" "}
          Absent
          <br />
          <b
            style={{
              color: "#FC5132",
              fontSize: "20px",
              padding: "5px",
              alignContent: "center"
            }}
          >
            {processedData.Absent || 0}
          </b>
        </div>
        <div className={classes.legendItem}>
          <span style={{ backgroundColor: "#2962ff", fontSize: "20px" }} /> Not
          Taken <br />
          <b
            style={{
              color: "#3358B8",
              fontSize: "20px",
              padding: "5px",
              alignContent: "center"
            }}
          >
            {processedData["Not Taken"] || 0}
          </b>
        </div>
      </div>
    </>
  );
};

export default DonutChart;
