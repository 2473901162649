import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import compose from "recompose/compose";
import GridItem from "components/Grid/GridItem.jsx";
import { withRouter } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.jsx";
import store from "store";
import { Grid } from "@material-ui/core";
const styles = (theme) => ({
  heading: {
    textAlign: "center",
    fontWeight: 900,
    lineHeight: 1.1,
  },
  subHeading: {
    fontWeight: 700,
    lineHeight: 1.1,
  },
  proceed: {
    color: "#fff",
    backgroundColor: "#337ab7",
    borderColor: "#2e6da4",
    padding: ".5% 7%",
    width: "max-content",
  },
  examHeading: {
    color: "white",
    backgroundColor: "#f7931e",
    padding: "5px 0",
    fontWeight: 900,
    // minHeight: theme.spacing(9)
  },
  instruction: {
    "& li": {
      color: "black",
      fontWeight: 400,
    },
  },
});
class Instruction extends React.Component {
  checkedFlag = false;
  constructor(props) {
    super(props);
    this.props = props;
  }
  isChecked(val) {
    this.checkedFlag = val.target.checked;
  }
  proceed() {
    const { history } = this.props;
    if (!this.checkedFlag) {
      alert("Please accept terms and conditions before proceeding.");
    } else {
      history.push("/exam-portal", { ...this.props.location.state });
    }
  }
  render() {
    const { classes } = this.props;
    const { test_type = "" } = this.props.location.state;
    return (
      <React.Fragment>
        <GridContainer
          className={classes.instruction}
          style={{ background: "white" }}
        >
          <GridItem xs={12} className={classes.examHeading}>
            <Typography
              component="h4"
              variant="h5"
              style={{ padding: "1% 10%", fontWeight: "bold" }}
            >
              General Instructions
            </Typography>
          </GridItem>
          <GridItem>
            <GridItem xs={12}>
              <h3 className={classes.heading}>
                Please read the instructions carefully
              </h3>
            </GridItem>
            <GridItem xs={12}>
              <h3 className={classes.subHeading}>
                <u>General Instructions:</u>
              </h3>
            </GridItem>
            {store.get("user").level == "19189210" ? (
              <Grid item container direction="column">
                <Grid item container direction="row" justify="space-around">
                  <Grid>
                    <Typography>Number of Questions: 50</Typography>
                  </Grid>
                  <Grid>
                    <Typography>Duration: 90 mins</Typography>
                  </Grid>
                  <Grid>
                    <Typography>Total Marks: 200</Typography>
                  </Grid>
                </Grid>
                <br />
                <Grid item>
                  <table
                    border="1"
                    style={{ borderCollapse: "collapse", width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th align="center">Section</th>
                        <th align="center">Questions</th>
                        <th align="center">Subject</th>
                        <th align="center">For Correct Attempted</th>
                        <th align="center">For Wrong Attempted</th>
                        <th align="center">Max. Marks</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td align="center">I</td>
                        <td align="center">1-15</td>
                        <td align="center">MATHS</td>
                        <td align="center">+4M</td>
                        <td align="center">-1M</td>
                        <td align="center">60</td>
                      </tr>
                      <tr>
                        <td align="center">II</td>
                        <td align="center">16-30</td>
                        <td align="center">PHYSICS</td>
                        <td align="center">+4M</td>
                        <td align="center">-1M</td>
                        <td align="center">60</td>
                      </tr>
                      <tr>
                        <td align="center">III</td>
                        <td align="center">31-45</td>
                        <td align="center">CHEMISTRY</td>
                        <td align="center">+4M</td>
                        <td align="center">-1M</td>
                        <td align="center">60</td>
                      </tr>
                      <tr>
                        <td align="center">IV</td>
                        <td align="center">46-50</td>
                        <td align="center">REASONING</td>
                        <td align="center">+4M</td>
                        <td align="center">0</td>
                        <td align="center">20</td>
                      </tr>
                    </tbody>
                  </table>
                </Grid>
              </Grid>
            ) : (
              <React.Fragment>
                <GridItem xs>
                  <ol>
                    <li>
                      <ul style={{ listStyleType: "circle" }}>
                        <li>
                          Total duration of JEE Main Paper- 1 & JEE Main Paper-
                          2 is 180 min.
                        </li>
                        <li>
                          JEE Paper- 2 is for Mathematics, Aptitude Test &
                          Drawing. The Drawing test is required to be done on
                          separate drawing sheet, which is not part of the
                          current mock test.
                        </li>
                      </ul>
                    </li>
                    <li>
                      The clock will be set at the server. The countdown timer
                      in the top right corner of screen will display the
                      remaining time available for you to complete the
                      examination. When the timer reaches zero, the examination
                      will end by itself. You will not be required to end or
                      submit your examination.
                    </li>
                    <li>
                      The Questions Palette displayed on the right side of
                      screen will show the status of each question using one of
                      the following symbols:
                      <ol>
                        <li>
                          <img
                            src="./images/SVG/not-visited.svg"
                            width="25px"
                            height="25px"
                            alt=""
                          />{" "}
                          You have not visited the question yet.
                        </li>
                        <li>
                          <img
                            src="./images/SVG/not-answered.svg"
                            width="25px"
                            height="25px"
                            alt=""
                          />{" "}
                          You have not answered the question.
                        </li>
                        <li>
                          <img
                            src="./images/SVG/answered.svg"
                            width="25px"
                            height="25px"
                            alt=""
                          />{" "}
                          You have answered the question.
                        </li>
                        <li>
                          <img
                            src="./images/SVG/mark-for-review.svg"
                            width="25px"
                            height="25px"
                            alt=""
                          />{" "}
                          You have NOT answered the question, but have marked
                          the question for review.
                        </li>
                        <li>
                          <img
                            src="./images/SVG/answered_marked_for_review.svg"
                            width="25px"
                            height="25px"
                            alt=""
                          />{" "}
                          The question(s) "Answered and Marked for Review" will
                          be considered for evalution.
                        </li>
                      </ol>
                    </li>
                    <li>
                      You can click on the "&lt;" arrow which apperes to the
                      left of question palette to collapse the question palette
                      thereby maximizing the question window. To view the
                      question palette again, you can click on "&gt;" which
                      appears on the right side of question window.
                    </li>
                    <li>
                      You can click on your "Profile" image on top right corner
                      of your screen to change the language during the exam for
                      entire question paper. On clicking of Profile image you
                      will get a drop-down to change the question content to the
                      desired language.
                    </li>
                    {/* <li>
                                       You can click on  to navigate to the bottom and  to navigate to top of the question are, without scrolling.
                                   </li> */}
                  </ol>
                </GridItem>
                <GridItem xs={12}>
                  <Typography variant="h6" className={classes.subHeading}>
                    <u>Navigating to a Question:</u>
                  </Typography>
                </GridItem>
                <GridItem>
                  <ol start="6">
                    <li>
                      To answer a question, do the following:
                      <ol type="a">
                        <li>
                          Click on the question number in the Question Palette
                          at the right of your screen to go to that numbered
                          question directly. Note that using this option does
                          NOT save your answer to the current question.{" "}
                        </li>
                        <li>
                          Click on <strong> Save & Next </strong> to save your
                          answer for the current question and then go to the
                          next question.
                        </li>
                        <li>
                          Click on Mark for <strong>Review & Next</strong> to
                          save your answer for the current question, mark it for
                          review, and then go to the next question.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </GridItem>
                <GridItem xs={12}>
                  <Typography variant="h6" className={classes.subHeading}>
                    <u>Answering a Question:</u>
                  </Typography>
                </GridItem>
                <GridItem>
                  <ol start="7">
                    <li>
                      Procedure for answering a multiple choice type question:
                      <ol type="a">
                        <li>
                          To select you answer, click on the button of one of
                          the options.
                        </li>
                        <li>
                          To deselect your chosen answer, click on the button of
                          the chosen option again or click on the Clear Response
                          button
                        </li>
                        <li>
                          To change your chosen answer, click on the button of
                          another option
                        </li>
                        <li>
                          To save your answer, you MUST click on the Save & Next
                          button.
                        </li>
                        <li>
                          To mark the question for review, click on the Mark for
                          Review & Next button.
                        </li>
                      </ol>
                    </li>
                    <li>
                      To change your answer to a question that has already been
                      answered, first select that question for answering and
                      then follow the procedure for answering that type of
                      question.
                    </li>
                  </ol>
                </GridItem>
                <GridItem xs={12}>
                  <Typography variant="h6" className={classes.subHeading}>
                    <u>Navigating through sections:</u>
                  </Typography>
                </GridItem>
                <GridItem>
                  <ol start="9">
                    <li>
                      Sections in this question paper are displayed on the top
                      bar of the screen. Questions in a section can be viewed by
                      click on the section name. The section you are currently
                      viewing is highlighted.
                    </li>
                    <li>
                      After click the Save & Next button on the last question
                      for a section, you will automatically be taken to the
                      first question of the next section.
                    </li>
                    <li>
                      You can shuffle between sections and questions anything
                      during the examination as per your convenience only during
                      the time stipulated.
                    </li>
                    <li>
                      Candidate can view the corresponding section summery as
                      part of the legend that appears in every section above the
                      question palette.
                    </li>
                  </ol>
                </GridItem>
                {test_type === "JEEMAIN_2022" && (
                  <React.Fragment>
                    <GridItem xs={12}>
                      <Typography variant="h6" className={classes.subHeading}>
                        <u>Marking Scheme for JEE MAIN - 2022 Models:</u>
                      </Typography>
                    </GridItem>
                    <GridItem>
                      <ol start="13">
                        <li>
                          For Paper 1 and Paper 2, each subject will have two
                          sections.Section A will be of Multiple Choice type
                          (MCQs) and Section B will contain questions whose
                          answers are to be filled in as numerical value.
                        </li>
                        <li>
                          In Section B, Candidate have to attempt any 05
                          questions out of 10.There will be negative marking for
                          both Section A and Section B.
                        </li>
                        <li>
                          For each question in Section B, enter the correct
                          integer value of answer using the mouse and the
                          on-screen virtual numeric keypad in the place
                          designated to enter the answer.
                        </li>
                        <li>
                          For Section B, the answer should be rounded off to the
                          nearest Integer.
                        </li>
                      </ol>
                    </GridItem>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            <GridItem xs={12}>
              <hr />
              <h3
                style={{
                  padding: "1%",
                  fontSize: 20,
                  fontWeight: 400,
                  color: "#a94442",
                }}
              >
                {" "}
                Please note all questions will appear in your default language.
                This language can be changed for a particular question later on.{" "}
              </h3>
              <hr />
              <h3 style={{ padding: "1%", fontSize: 14, fontWeight: 400 }}>
                <input type="checkbox" onChange={this.isChecked.bind(this)} />I
                have read and understood the instructions. All computer hardware
                allotted to me are in proper working condition. I declare that I
                am not in possession of / not wearing / not carrying any
                prohibited gadget like mobile phone, bluetooth devices etc. /any
                prohibited material with me into the Examination Hall.I agree
                that in case of not adhering to the instructions, I shall be
                liable to be debarred from this Test and/or to disciplinary
                action, which may include ban from future Tests / Examinations
              </h3>
              <hr />
            </GridItem>
            <GridItem item xs={12} container justify="space-around">
              <Typography
                component="h6"
                className={classes.proceed}
                onClick={this.proceed.bind(this)}
              >
                Proceed
              </Typography>
            </GridItem>
          </GridItem>
          <GridItem>
            {" "}
            <br />{" "}
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(Instruction);
