import {
  Grid,
  Input,
  makeStyles,
  Typography,
  Container,
  Snackbar,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import theme from "../assets/theme";
import Button from "components/CustomButtons/Button.jsx";
import store from "store";
import { useDispatch, useSelector } from "react-redux";
import { editProfileLoading } from "../redux/actions/editProfileAction/actionCreator";
import NetworkProgress from "./NetworkProgress";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { sendConfirmationSMS } from "../apis/smsService";

const useStyles = makeStyles(() => ({
  text: {
    "&:after": {
      borderBottomColor: theme.palette.primary.main,
    },

    "&:not(.Mui-disabled):hover::before": {
      borderColor: theme.palette.primary.main,
    },
  },
  editProfile: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 3%",
    },
  },
  successColor: {
    color: theme.palette.primary.main,
  },
}));

const ProfileEditForm = () => {
  const history = useHistory();
  const { hostname } = window.location;
  let payAmount = {
    Foundation: 100,
    BiPC: 100,
    MPC: 100,
    MAINS2021: 2500,
    MA: 6000,
    N: 6000,
    MAN: 9000,
    crash: 5900,
    fulltests_2021: 1260,
    crashtests_2020: 580,
  };
  const profileData = {
    fatherName: "",
    previousSchool: "",
    presentAddress: "",
    alternateContact: "",
    statusType: "unpaid",
  };
  const formErrors = {
    fatherName: null,
    previousSchool: null,
    presentAddress: null,
    alternateContact: null,
  };
  const user = store.get("user");
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(profileData);
  const [error, setError] = useState(formErrors);
  const profileEdit = useSelector((state) => state.profileEdit);
  const { data, isLoading } = profileEdit;
  //payment handler
  const logPayment = (body, confirmationText) => {
    const url = window.location.host.includes("localhost")
      ? "https://onesaz.com"
      : window.location.origin;
    const ax = axios.create({
      baseURL: url + "/api",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    ax.post("/pay/success", JSON.stringify(body)).then((res) => {
      if (res.status == 200) {
        console.log(res);
        const user = store.get("user");
        dispatch(
          editProfileLoading(user._id, {
            ...formData,
            statusType: "paid",
          })
        );
        user.package = "allindiatests_2022";
        user.level = 2119611;
        store.set("user", {
          ...user,
          ...formData,
          statusType: "paid",
        });

        sendConfirmationSMS(user.phoneNumber || user.phoneno, confirmationText)
          .then((res) => {
            history.push("/home");
          })
          .catch((err) => {
            console.log("payment", err);
            history.push("/home");
          });
      }
    });
  };
  //update profile details
  const updateProfile = (userId, data, response) => {
    const url = window.location.host.includes("localhost")
      ? "https://onesaz.com"
      : window.location.origin;
    const ax = axios.create({
      baseURL: url + "/api",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    ax.post(`/users/editprofile/${userId}`, { ...data, statusType: "paid" })
      .then((res) => {
        if (res.status == 200) {
          const confirmationText = `Student, Your registration is successful!! ${
            user.examDate
              ? `Your exam details are: Date: ${user.examDate} and Time slot: ${
                  user.examTimeSlot
                }`
              : ""
          }. ${window.location.origin}/sign-in`;
          logPayment(response, confirmationText);
        }
      })
      .catch((error) => {
        console.log("payment", error);
        return error.message;
      });
  };
  const updatePaymentStatus = (response) => {
    updateProfile(user._id, formData, response);
    // alert(store.get("user").statusType);
    // console.log(response);
  };
  const payment = (type) => {
    const url = window.location.host.includes("localhost")
      ? "https://onesaz.com"
      : window.location.origin;
    let user = JSON.parse(window.localStorage.getItem("user"));
    // console.log(user._id);
    var requestData = {
      _id: user._id,
      key: "jmKsogxT",
      txnid: store.get("user").userId,
      // color: "primary",
      hash: "",
      amount: payAmount[type],
      firstname: user["name"],
      email: "support@onesaz.com",
      phone: user["phoneNumber"],
      productinfo: JSON.stringify({
        _id: user._id,
        package: "allindiatests_2022",
      }),
      service_provider: "payu_paisa",
      surl: url + "/api/pay/success",
      furl: url + "/api/paymentfailed",
      salt: "VCHHRU2kQZ", //"UhvE4JAdfK",
      // mode: "dropout" // non-mandatory for Customized Response Handling
    };
    var Handler = {
      responseHandler: (BOLT) => {
        if (BOLT.response.txnStatus != "CANCEL") {
          console.log(BOLT.response);
          updatePaymentStatus(BOLT.response);
        }
        return BOLT.response;
      },
      catchException: function(BOLT) {},
    };
    const ax = axios.create({
      baseURL: url + "/api",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    ax.post("/pay/request", JSON.stringify(requestData)).then((res) => {
      console.log(res);
      if (res.data.status == "SUCCESS") {
        requestData.hash = res.data.hash;
        requestData.txnid = res.data.txnid;
        window.bolt.launch(requestData, Handler);
      }
    });
    //  window.bolt.launch( requestData , Handler );
  };
  //---------------snackbar utils----------------
  const [snackBarState, setSnackBarState] = useState({
    openSnackBar: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, openSnackBar } = snackBarState;
  const handleCloseSnackBar = () => {
    setSnackBarState({ ...snackBarState, openSnackBar: false });
  };

  // validate input values
  const validateForm = () => {
    let values = formData;
    let errors = { ...formErrors };
    let formIsValid = true;
    if (!values.fatherName) {
      formIsValid = false;
      errors.fatherName = "*Please enter your father's name.";
    }

    if (!values.previousSchool) {
      formIsValid = false;
      errors.previousSchool = "*Please enter previous school name";
    }

    if (!values.presentAddress) {
      formIsValid = false;
      errors.presentAddress = "*Please enter present address";
    }
    if (/^\d{10}$/.test(values.alternateContact) === false) {
      formIsValid = false;
      errors.alternateContact = "*Please enter a valid 10 digit contact no.";
    }
    if (values.alternateContact === user.phoneNumber) {
      formIsValid = false;
      errors.alternateContact =
        "*Please enter a different phone no. then what used for registration";
    }
    setError(errors);

    return formIsValid;
  };
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    Object.keys(error).forEach((i) => (error[i] = null));
  };

  const handleProfileEdit = () => {
    if (validateForm()) {
      if (hostname.includes("bhashyam")) {
        payment(user.course);
      } else {
        dispatch(editProfileLoading(user._id, formData));
      }
    }
  };
  useEffect(() => {
    // update user with newly added data in store
    if (data.status && data.status === "success") {
      const profile = {
        ...user,
        ...formData,
        statusType: hostname.includes("bhashyam")
          ? "paid"
          : formData.statusType,
      };
      store.set("user", profile);
      setSnackBarState({
        openSnackBar: true,
        vertical: "top",
        horizontal: "center",
      });
      setTimeout(() => {
        history.push("/home");
      }, 1000);
    }
  }, [data]);
  const classes = useStyles();
  return (
    <Container maxWidth="lg">
      <br />
      <Grid
        container
        direction="column"
        spacing={2}
        className={classes.editProfile}
      >
        {profileEdit.error && (
          <Typography color="error">Something went wrong!</Typography>
        )}
        {isLoading && <NetworkProgress />}
        {data.status && data.status === "success" ? null : (
          <React.Fragment>
            <Grid item>
              <Typography color="error">
                Please complete your profile
                {hostname.includes("bhashyam") ? " and Make Payment " : " "}
                before proceeding to write exams.
                <sup>*</sup>
              </Typography>
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Input
                    placeholder="Father's name*"
                    inputProps={{ "aria-label": "Father's name" }}
                    fullWidth
                    type="text"
                    value={formData.fatherName}
                    name="fatherName"
                    onChange={handleChange}
                    classes={{ underline: classes.text }}
                  />
                  {error.fatherName && (
                    <Typography color="error" variant="body2">
                      {error.fatherName}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Input
                    placeholder="Previous school*"
                    inputProps={{ "aria-label": "Previous school" }}
                    fullWidth
                    type="text"
                    value={formData.previousSchool}
                    name="previousSchool"
                    onChange={handleChange}
                    classes={{ underline: classes.text }}
                  />
                  {error.previousSchool && (
                    <Typography color="error" variant="body2">
                      {error.previousSchool}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Input
                    placeholder="Present address (village/town name)*"
                    inputProps={{
                      "aria-label": "Present address (village/town name)",
                    }}
                    fullWidth
                    type="text"
                    value={formData.presentAddress}
                    name="presentAddress"
                    onChange={handleChange}
                    classes={{ underline: classes.text }}
                  />
                  {error.presentAddress && (
                    <Typography color="error" variant="body2">
                      {error.presentAddress}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Input
                    placeholder="Alternate contact no.*"
                    inputProps={{ "aria-label": "Alternate contact no." }}
                    fullWidth
                    type="tel"
                    value={formData.alternateContact}
                    name="alternateContact"
                    onChange={handleChange}
                    classes={{ underline: classes.text }}
                  />
                  {error.alternateContact && (
                    <Typography color="error" variant="body2">
                      {error.alternateContact}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <br />
            {/* // submit button */}
            <Grid container justify="flex-end">
              <Button round color="primary" onClick={handleProfileEdit}>
                {hostname.includes("bhashyam")
                  ? `Save Changes and Pay ₹${payAmount[user.course]}.00`
                  : "Save changes"}
              </Button>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
      {/* snackbar */}
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSnackBar}
        onClose={handleCloseSnackBar}
        message={"Profile updated!"}
        key={vertical + horizontal}
      />
    </Container>
  );
};

export default ProfileEditForm;
