// CategoryList.js
import React from "react";
import store from "store";
import { Grid, Typography } from "@material-ui/core";
import useStyles from "./styles"; // Adjust the path as necessary
import CatCard from "./Components/catCard"; // Adjust the path as necessary

import pinkAanalysis from "../../assets/img/navigationBarAssets/pinkAanalysis.svg";
import greenExamAanalysis from "../../assets/img/navigationBarAssets/greenExamAnalysis.svg";
import feeDetails from "../../assets/img/navigationBarAssets/feeDetails.svg";
import myClassroom from "../../assets/img/navigationBarAssets/myClassroom.svg";
import attendance from "../../assets/img/navigationBarAssets/attendance.svg";
import profileYellow from "../../assets/img/navigationBarAssets/profileYellow.svg";
import progressCard from "../../assets/img/navigationBarAssets/progressCard.svg";
import TopicWiseAnalysis from "../../assets/img/navigationBarAssets/topicWiseAnalysis.svg";
import liveClasses from "../../assets/img/navigationBarAssets/liveClasses.svg";
import library from "../../assets/img/navigationBarAssets/library.svg";

const ParentPortal = [
  { title: "View Exams", icon: pinkAanalysis, value: "exams" },
  { title: "Exam Analysis", icon: greenExamAanalysis, value: "analysis" },
  { title: "My Classroom", icon: myClassroom, value: "classRoom" },
  { title: "View Attendance", icon: attendance, value: "attendance" },
  { title: "Fee Details", icon: feeDetails, value: "feeDetails" },
  { title: "Pocket Money", icon: feeDetails, value: "pocketMoney" },
  { title: "View My Profile", icon: profileYellow, value: "profile" },
  { title: "Progress Card", icon: progressCard, value: "progressCard" }
];
const studentPortal = [
  { title: "View Exams", icon: pinkAanalysis, value: "exams" },
  { title: "Exam Analysis", icon: greenExamAanalysis, value: "analysis" },
  { title: "My Classroom", icon: myClassroom, value: "classRoom" },
  { title: "View Attendance", icon: attendance, value: "attendance" },
  // { title: "Fee Details", icon: feeDetails, value: "feeDetails" },
  { title: "View My Profile", icon: profileYellow, value: "profile" },
  { title: "Progress Card", icon: progressCard, value: "progressCard" },
  { title: "Live Classes", icon: liveClasses, value: "liveClasses" },
  { title: "View Library", icon: library, value: "viewLibrary" },
  {
    title: "Topic Wise Analysis",
    icon: TopicWiseAnalysis,
    value: "topicWiseAnalysis"
  },
  {
    title: "Previous Classes",
    icon: liveClasses,
    value: "PreviousClasses"
  }
];

const CategoryList = ({ handleCatClick }) => {
  const classes = useStyles();
  const user = store.get("user").accountType;
  const categories = user === "parent" ? ParentPortal : studentPortal;
  return (
    <>
      <Typography
        className={classes.catText}
        style={{ marginTop: "32x", marginBottom: "20px" }}
      >
        Categories
      </Typography>
      <div className={classes.catContainer}>
        <Grid container spacing={2}>
          {categories.map((category, index) =>
            store.get("user").institute === "6422b982a32e9b7c5498b129" &&
            (category.title === "Fee Details" ||
              category.title === "Pocket Money") ? null : (
              <Grid
                item
                xs={6}
                md={6}
                sm={4}
                lg={4}
                key={category.value}
                role="button"
                tabIndex={index}
                onClick={() => handleCatClick(category.value)}
                onKeyDown={event => {
                  if (event.key === "Enter" || event.key === " ") {
                    handleCatClick(category.value)();
                  }
                }}
                className={classes.cursor}
              >
                <CatCard title={category.title} icon={category.icon} />
              </Grid>
            )
          )}
        </Grid>
      </div>
    </>
  );
};

export default CategoryList;
