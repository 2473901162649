export const PAYMENT_STATUS = {
    Ok: "success",
    F: "failure",
    To: "timedOut",
    Pending: "pending",
    revert: "Reverted",
  };
  
  export const FORM_MODES = {
    CREATE: "CREATE",
    EDIT: "EDIT",
    UPDATE: "UPDATE",
  };
  
  export const PAYMENT_MODE = {
    CA: "cash",
    CC: "Credit card",
    DC: "Debit Card",
    CQ: "Cheque",
    NB: "Net Banking",
    UPI: "UPI",
  };
  