import { all } from "redux-saga/effects";
import editProfileSagaWatcher from "./editProfileAction/SagaEditProfile";
import notificationSagaWatcher from "./notificationsAction/SagaNotification";
import practiceSagaWatcher from "./practicePageActions/SagaPractice";
import resultSagaWatcher from "./resultPageActions/SagaResult";
function* rootSaga() {
  yield all([
    practiceSagaWatcher(),
    resultSagaWatcher(),
    notificationSagaWatcher(),
    editProfileSagaWatcher(),
  ]);
}
export default rootSaga;
