export default (theme) => ({
  root: {
    background: "#ecf0f3cc",
    minHeight: "100vh",
  },
  grid: {
    height: "100%",
  },
  quoteWrapper: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  actionButton: {
    textTransform: "none",
    background: "#08C5A7",
    boxShadow: "inset 1px 1px 1px #04E8C3, inset -1px -1px 1px #07B096",
    borderRadius: "16px",
    minWidth: "320px",
    minHeight: "58px",
    color: "white",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "18px",
    "&:hover, &:focus": {
      opacity: 1,
      background: "#08C5A7 !important",
    },
  },
  quote: {
    backgroundColor: theme.palette.common.neutral,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "url(/images/sign_up_1.jpg)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  quoteInner: {
    textAlign: "center",
    flexBasis: "600px",
  },
  quoteText: {
    color: theme.palette.common.white,
    fontWeight: 350,
    fontSize: "2rem",
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.common.white,
  },
  bio: {
    color: theme.palette.common.white,
  },
  contentWrapper: {},
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(12),
    paddingBototm: theme.spacing(1),
    //paddingLeft: theme.spacing * 1,
    //paddingRight: theme.spacing * 12,
    marginLeft: "auto",
    marginRight: "auto",
  },
  backButton: {},
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  form: {
    paddingLeft: "100px",
    paddingRight: "100px",
    paddingBottom: "125px",
    flexBasis: "700px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  dont: {
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    color: "rgba(49, 69, 106, 0.5)",
  },
  separator: {
    background: "#08C5A7",
    width: "55px",
    height: "3px",
    borderRadius: "5px",
  },
  registerHeader: {
    color: "#31456A",
    fontSize: "30px",
    fontWeight: "bold",
    lineHeight: "37px",
    paddingBottom: "20px",
  },
  title: {
    marginTop: theme.spacing(3),
  },
  subtitle: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(0.5),
  },
  facebookButton: {
    marginTop: theme.spacing(3),
    width: "100%",
  },
  facebookIcon: {
    marginRight: theme.spacing,
  },
  googleButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  googleIcon: {
    marginRight: theme.spacing,
  },
  sugestion: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  fields: {
    marginTop: theme.spacing(2),
  },
  textField: {
    width: "100%",
    "& + & ": {
      marginTop: theme.spacing(2),
    },
  },
  policy: {
    display: "flex",
    alignItems: "center",
  },
  policyCheckbox: {
    marginLeft: "-14px",
  },
  policyText: {
    display: "inline",
    color: theme.palette.text.secondary,
  },
  policyUrl: {
    color: theme.palette.text.primary,
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
  },
  progress: {
    display: "block",
    marginTop: theme.spacing(2),
    marginLeft: "auto",
    marginRight: "auto",
  },
  signInButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  signUp: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  signUpUrl: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  fieldError: {
    color: "red",
    marginBottom: theme.spacing,
    marginTop: theme.spacing,
  },
  // submitError: {
  //   color: theme.palette.danger.main,
  //   alignText: 'center',
  //   marginBottom: theme.spacing,
  //   marginTop: theme.spacing * 2
  // }
  // styling class grid of bhashyam
  selectClassMenu: {
    width: '300px',
    padding: '10px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: '10px',
    '& > li': {
      backgroundColor: '#00bfa5',
      borderRadius: '12px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '50px',
      width: '96px',
      color: 'white',
      '&:hover': {
        '& > p': {
          color: '#31456A',
          '& sup': {
            color: '#31456A'
          }
        }
      }
    },
    '& $selectedMenuItem' : {
      color: '#31456A'
    }
  },
  selectedMenuItem : {
  },
  selectClassPaper: {
    borderRadius: '12px',
    width: '330px',
  },
  classOptionText: {
    fontSize: '20px'
  },
  powerTh: {
    fontSize: '16px',
    letterSpacing: 0,
    opacity: '.8',
    textAlign: 'center',
    lineHeight: '16px',
    marginLeft: '3px'
  }
});
