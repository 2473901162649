import store from "store";
const profileEditCondition = () => {
  const { hostname } = window.location;
  const user = store.get("user");
  return (
    (hostname.includes("bhashyam") &&
      user.level == "2119611" &&
      user.statusType == "unpaid") ||
    (hostname.includes("viitjee") &&
      user.level == "17410" &&
      !user.fatherName) ||
    (hostname.includes("agastya") &&
      user.level == "19189210" &&
      !user.fatherName)
  );
};
export default profileEditCondition;
