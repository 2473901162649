import examAxios from "apis/examServerAxios";
import * as moment from "moment";
import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import compose from "recompose/compose";
import store from "store";
import Advance from "./Advance";
import "./App.css";
import CustomTypePanel from "./CustomTypePanel";
import HomePanel from "./HomePanel";
import NetworkProgress from "./NetworkProgress";
import PDFTest from "./PDFTest";
// var examServerUrl = require("./nta-constants").examServerUrl;

class ExamPortal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      schema: null,
    };
    this.props = props;
  }
  isLoggedin = false;
  testname = null;
  startedTime = 0;
  remainingTime = 0;
  id = store.get("user")._id;
  loadingMsg = "Loading...";
  componentDidMount() {
    console.log(this.props.location.state);
    if (!this.props.location.state) {
      const { history } = this.props;
      history.push("/home");
    } else {
      ///////////////////////////////////////get schema//////////////////////////////

      ///////////////////////////////////////////////////////////////////////////////
      store.set("currentExam", this.props.location.state);
      // this.isLoggedin = true;
      // let url = examServerUrl + "/exams/tests";
      let opts = {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          student_id: this.id,
        }),
      };
      let val = JSON.stringify(opts);
      console.log(val);
      this.remainingTime =
        Number(this.props.location.state.test_duration) +
        Number(
          moment(this.props.location.state.time_started)
            .subtract(moment.now())
            .format("x")
        );
      if (this.remainingTime < 0) {
        this.loadingMsg = "Exam has been expired";
      }
      console.log(this.props.location.state.test_status);
      const body = JSON.stringify({
        student_id: this.id,
        test_name: this.props.location.state.test_name,
      });
      switch (this.props.location.state.test_status) {
        // case "NOT_STARTED":
        //             examAxios.post('/exams/writetest',body).then(res => res.data.payload).then(res => {
        //                 console.log(res);
        //                 this.remainingTime = this.props.location.state.test_duration;
        //                   this.forceUpdate()
        //             }).catch(err=> {
        //               console.log(err)
        //             }).then( val=>
        //               this.forceUpdate()
        //             );
        //             break;
        case "NOT_STARTED1":
        case "STARTED1":
          let url = "/exams/writetest";
          // url = '/2018p1.json';
          examAxios
            .post(url, body)
            .then((res) => res.data.payload)
            .then((res) => {
              console.log(res);
              this.remainingTime = this.props.location.state.test_duration;
              this.forceUpdate();
            })
            .catch((err) => {
              console.log(err);
            })
            .then((val) => this.forceUpdate());
          break;
      }
    }

    // examAxios.post(url, opts.body)
    //   .then(res => {console.log(res); return res.data})
    //   .then(res => {
    //     this.testname = res.payload.Items.filter(
    //       item => !item.active
    //     )[0].test_name;
    //     this.startedTime = res.payload.time_started;
    //     this.testDuration = res.payload.test_duration;
    //     console.log(this.testname);
    //   }).catch(err => {

    //       this.loadingMsg = err.toString();
    //   }).then( val=> {
    //      this.forceUpdate();
    //   });
  }
  componentDidMount() {
    const url = `/exams/getSchema?testName=${
      this.props.location.state.test_name
    }`;
    examAxios
      .get(url)
      .then((res) => res.data.output)
      .then((res) => {
        // console.log(res);
        this.setState({ schema: res });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  render() {
    const { state } = this.props.location;
    // console.log(this.state.schema);
    if (
      this.props.location.state.test_type.includes("CUSTOM") &&
      state.pdfLink
    ) {
      return (
        <Redirect
          to={{
            pathname: "/writePDFtest",
            search: `?examDuration=${state.test_duration}&user=${
              this.id
            }&test_type=${state.test_type}&displayname=${
              state.testDisplayName
            }&examName=${state.test_name}&pdfLink=${state.pdfLink}`,
          }}
        />
      );
    }

    return this.remainingTime >= 0 ? (
      <React.Fragment>
        {this.props.location.state.test_type &&
        (this.props.location.state.test_type.includes("JEE ADVANCED") ||
          (this.state.schema && this.state.schema.questions)) ? (
          <Advance
            paper={this.props.location.state.paper}
            examDuration={this.props.location.state.test_duration}
            user={this.id}
            displayName={this.props.location.state.testDisplayName}
            examName={this.props.location.state.test_name}
            schema={this.state.schema}
          />
        ) : this.props.location.state.test_type.includes("CUSTOM") ? (
          <CustomTypePanel
            examDuration={this.props.location.state.test_duration}
            user={this.id}
            test_type={this.props.location.state.test_type}
            displayname={this.props.location.state.testDisplayName}
            examName={this.props.location.state.test_name}
          />
        ) : (
          <HomePanel
            examDuration={this.props.location.state.test_duration}
            user={this.id}
            test_type={this.props.location.state.test_type}
            displayname={this.props.location.state.testDisplayName}
            examName={this.props.location.state.test_name}
          />
        )}
      </React.Fragment>
    ) : (
      <NetworkProgress />
    );
  }
}

export default compose(withRouter)(ExamPortal);
