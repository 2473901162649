import { makeStyles } from "@material-ui/core/styles";

const feeDetailsStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
    "& .MuiPaper-root": {
      background: "transparent",
    },
  },
  headContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  title: {
    fontWeight: 700,
    fontSize: "1.125rem",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(1),
    paddingTop: theme.spacing(8),
  },
  subTitleContainer: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  subHeading: {
    fontSize: "1rem",
    fontWeight: 700,
    wordBreak: "break-word"
  },
  seperator: {
    display: "inline",
    justifyContent: "center",
    alignItems: "center",
    width: "0.5rem",
    height: "1.25rem",
    backgroundColor: "#737373",
    color: "#737373",
    borderRadius: "0.125rem",
    marginRight: "0.125rem",
    marginLeft: "0.125rem",
  },
  maninContentContainer: {
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  buttonsContainer: {
    marginTop: theme.spacing(1),
    overflowX: "auto",
    display: "flex",
  },
  activeBtn: {
    flexShrink: 0,
    flexGrow: 0,
    borderRadius: 40,
  },
  inActiveBtn: {
    boxShadow: "4px 4px 8px 0px #FFFFFFBF",
    boxShadow: "2px 2px 8px 0px #BDC2C7BF inset",
    flexShrink: 0,
    flexGrow: 0,
    border: "1px solid #D4D4D4",
    background: "#EBEFF3",
    borderRadius: 40,
    color:"#2A2A2A !important"
  },
  descTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  accordion: {
    // margin: "16px 0",
    background: "#EBEFF3",
      boxShadow: `4px 4px 8px rgba(189, 194, 199, 0.75),
      8px 8px 12px rgba(189, 194, 199, 0.25),
     -4px -4px 8px rgba(255, 255, 255, 0.75),
     -8px -8px 12px rgba(255, 255, 255, 0.25)`,
      borderRadius: "16px",
    // boxShadow: "none",
    border: "none",
    borderTop: "none",
  },
  descFeeName: {
    fontWeight: 400,
    fontSize: "0.9rem",
  },
  descFeeValue: {
    fontWeight: 600,
    fontSize: "0.9rem",
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  detailsViewContainer: {
    borderTopLeftRadius: theme.spacing(2),
    borderTopRightRadius: theme.spacing(2),
    padding: theme.spacing(2),
    paddingTop: 0,
    background: "#FFFFFF",
    [theme.breakpoints.up("sm")]: {
      minWidth: 500,
      margin: "auto"
    }
  },
  drawerPaper: {
    background: "transparent",
    boxShadow: 0,
  },
  drawerTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "4.25rem",
  },
  drawerTitle: {
    fontWeight: 700,
    fontSize: "1.25rem",
  },
  mainDivider: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  appBar: {
    position: "relative",
    background: "transparent",
  },
  pageTitle: {
    flex: 1,
    fontWeight: 700,
    fontSize: "1.125rem",
    color: theme.palette.text.primary,
  },
  autoHeight: {
    height: "auto",
  },
  dialog: {
    background: "#EBEFF3",
  },
  removePrev: {
    padding: 0,
    background: "#EBEFF3",
  },
  dialogPaper: {
    background: "#EBEFF3",
    minHeight: "100vh",
  },
  txnContainer: {
    // marginTop: "1rem"
  },
  txnDetailsText: {
    fontWeight: 700,
    fontSize: "1rem",
    marginBottom: "0.5rem"
  }, 
  txnDescContainer: {
    marginTop: "1rem",
    widows: "100%"
  },
  paidDetailsContainer: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: 800,
      margin: "auto"
    },
    marginBottom: "2rem !important"
  }
}));

export default feeDetailsStyles;
