import { useState, useEffect } from 'react'
import axios from 'axios'
import store from 'store'
import { ColorLensTwoTone } from '@material-ui/icons'
import { getStudentAllDetails } from '../../../apis/attendance'

const useStudentData = () => {
  const [stuFeeData, setStuFeeData] = useState({})
  const [avatar, setAvatar] = useState('')
  const [studentAdmissionNo, setStudentAdmissionNo] = useState('')
  const [omrNo, setOmrNo] = useState('')

  // async function fetchStudentData() {
  //   var ENV = process.env.NODE_ENV || "development";
  //   const host =
  //     ENV === "development"
  //       ? "http://localhost:7077"
  //       : "https://admin.onesaz.com/api";
  //   const stuId = store.get("user").accountType
  //     ? store.get("user").studentIds
  //     : store.get("user")._id;

  //   const instituteId = store.get("user").institute;
  //   try {
  //     const response = await axios.get(`${host}/parentPortal/${stuId}`, {
  //       params: {
  //         instituteId: instituteId, // Include instituteId as a query parameter
  //       },
  //     });
  //     setStuFeeData(response.data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }
  async function fetchStudentData () {
    const instituteId = store.get('user').institute
    const stuId = store.get('user').accountType
      ? store.get('user').studentIds
      : store.get('user')._id
    try {
      const response = await getStudentAllDetails(
        {
          instituteId: instituteId
        },
        stuId
      )
      setStuFeeData(response)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchStudentData()
  }, [])
  useEffect(() => {
    if (stuFeeData) {
      if (stuFeeData.student) {
        setStudentAdmissionNo(stuFeeData.student.admissionNo)
        setOmrNo(stuFeeData.student.applicationNo)
        setAvatar(stuFeeData.student.avatar)
      }
    }
  }, [stuFeeData])

  return [studentAdmissionNo, omrNo, stuFeeData, avatar]
}
export default useStudentData
