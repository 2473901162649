import { useState, useEffect } from "react";
import store from "store";
import { useDispatch } from "react-redux";

import examAxios from "../../../apis/examServerAxios";

const useTimeData = ({ exam, testsObj, group, values  }) => {
  const dispatch = useDispatch();
  const fetchedData = [];
  const a = {
    MPC: "JEEMAIN_2022",
    BiPC: "NEET_2021",
  };


  const [timeData, setTimeData] = useState(null);
  const [data, setData] = useState([]);
  const [filterType, setFilterType] = useState([]);

  useEffect(() => {
    const sort = (test, key) => {
      const id = store.get("user").accountType
        ? store.get("user").studentIds
        : store.get("user")._id;

      let body = JSON.stringify({
        student_id: id,
        code: null,
        test_name: test,
      });

      examAxios
        .post("/analysis/timespent", body)
        .then((response) =>
          setData((prevData) => [
            ...prevData,
            { [key]: response.data.payload.Items },
          ])
        )
        .catch((error) => console.error(error));
    };

    Object.entries(testsObj).filter((item) => sort(item[1], item[0]));
  }, [testsObj, exam]);
  useEffect(() => {
    const updatedExamData = data.map((dataObj) => {
      const [testName] = Object.keys(dataObj);

      for (const exam of values) {
        if (exam.testName === testName) {
          return Object.assign({}, dataObj, { examType: exam.examType });
        }
      }
      return dataObj;
    });

    if (exam === "") {
      setFilterType(
        updatedExamData.filter((item) => item.examType === a[group])
      );
    } else {
      setFilterType(updatedExamData.filter((item) => item.examType === exam));
    }
  }, [exam, data, values]);
  useEffect(() => {
    // const typeWiseData =
    const result = filterType
      .map((test) => {
        const testName = Object.keys(test)[0];
        const subjects = {};
        test[testName].forEach((question) => {
          if (!subjects[question.subject]) {
            subjects[question.subject] = 0;
          }
          subjects[question.subject] += question.timespent;
        });
        return {
          [testName]: subjects,
        };
      })
      .reduce((acc, obj) => {
        const testName = Object.keys(obj)[0];
        acc[testName] = obj[testName];
        return acc;
      }, {});

    setTimeData(result);
  }, [filterType]);

  return [timeData];
};
export default useTimeData;
