// FeeDueAmount.js
import React, { useEffect, useMemo } from "react";
import store from "store";
// mui
import Grid from "@material-ui/core/Grid";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
// import Skeleton from "@material-ui/lab/Skeleton";
// components
import ArrowButton from "./Components/ArrowButton";
import WrapperCard from "./Components/WrapperCard";
// hooks
import useFetch from "../../hooks/useFetch";
// apis
import { getStudentDueInfo } from "../../apis/finance";
// utils
import numberWithCommas from "../../utils/numberWithCommas";

const FeeDueAmount = ({ handleClick,background }) => {
  const { loginIndex, institute, studentIds } = store.get("user") || {};
  const [isLoading, error, data, fetchData, resetFetch] = useFetch(
    getStudentDueInfo
  );

  const isParent = /^[0-9]{10}$/.test(loginIndex) && !!studentIds;

  // if(!userId || !isParent) return null;

  // const details_fetched = useMemo(() => {
  //   return data && data.status === "SUCCESS";
  // }, [data, isLoading, error]);

  // const error_fetching = useMemo(() => {
  //   if (!data) return false;
  //   if (data && data.status === "FAILURE") return data.message;
  //   return false;
  // }, [data, isLoading, error]);

  // const ins_current_ay = useMemo(() => {
  //   if (!data) return "";
  //   return data.ins_current_ay;
  // }, [data]);

  const displayData = useMemo(() => {
    if (!data || !data.details) return null;
    if (!data.status === "SUCCESS") return null;
    const keys = Object.keys(data.details);
    const items = keys
      .map((item) => ({
        due:
          data.details[item].balance &&
          !Number.isNaN(Number(data.details[item].balance))
            ? data.details[item].balance
            : 0,
        ay: item,
      }))
      .filter(
        (item) =>
          item.due && !Number.isNaN(Number(item.due)) && Number(item.due) > 0
      );
    if (items.length > 0) return items[0];
    return null;
  }, [data]);

  useEffect(() => {
    if (isParent) {
      const body = {
        admissionNo: `${loginIndex}`,
        instituteId: institute,
        studentId: studentIds,

        // admissionNo: "9347050516",
        // instituteId: "646634c8a400ecffc1306d0d",
        // studentId: "646cad9188be64127bebe1a0",
      };

      fetchData(body);
    }

    return () => {
      resetFetch();
    };
  }, []);

  if (!isLoading && (!displayData || !displayData.due)) return null;

  if(isLoading) return null;

  // if (isLoading) {
  //   return (
  //     <WrapperCard>
  //       <div sx={{ width: "100%" }}>
  //         <Skeleton animation="wave" height={24} width={100} />
  //         <Skeleton animation="wave" height={24} width={100} />
  //       </div>
  //     </WrapperCard>
  //   );
  // }

  return (
    <ArrowButton
      handleClick={handleClick}
      bgColor={background?background:"#A83B96"}
      endIcon={<ArrowForwardIosIcon style={{ color: "white" }} />}
      content={
        <Grid
          container
          direction="column"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Grid item alignItems="flex-start" justifyContent="flex-start">
            <span
              style={{
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "20px",
                color: "white",
              }}
            >
              {`Fee Due Amount (${displayData.ay})`}
            </span>
          </Grid>
          <Grid item alignItems="flex-start" justifyContent="flex-start">
            <b
              style={{
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "24px",
                color: "#FFFFFF",
              }}
            >
              {`₹${numberWithCommas(displayData.due)}`}
            </b>
          </Grid>
        </Grid>
      }
    />
  );
};

export default FeeDueAmount;
