import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
// import DeviceOrientation, { Orientation } from "react-screen-orientation";
import {
  getZmMeetings,
  getZoomInteractiveMeetingDetails,
} from "apis/videoService";
import $ from "jquery";
import React, { Fragment, useState, useEffect } from "react";
import store from "store";
import BottomNav from "../BottomNav";
import Header from "../Header";
import VideoListComponent from "./VideoListComponent";
window.jQuery = $;
window.$ = $;
global.jQuery = $;
// it's option if you want to change the WebSDK dependency link resources. setZoomJSLib must be run at first
// if (!false) ZoomMtg.setZoomJSLib('node_modules/@zoomus/websdk/dist/lib', '/av'); // CDN version default
// else ZoomMtg.setZoomJSLib('https://jssdk.zoomus.cn/1.7.4/lib', '/av'); // china cdn option
// ZoomMtg.setZoomJSLib('http://localhost:9999/node_modules/@zoomus/websdk/dist/lib', '/av'); // Local version default, Angular Project change to use cdn version

const styles = (theme) => ({
  homeButton: {
    padding: "4%",
  },
  videoBg: {
    minHeight: "90vh",
    height: "max-content",
    overflow: "scroll",
    minWidth: "100vw",
    background: "#F0F3F5",
  },
  videoPlayer: {
    marginTop: "20px",
    height: "100%",
    // width: '100%',
    minHeight: "400px",
    overflow: "scroll",
  },
  videoCount: {
    color: "#31456A",
    fontSize: "30px",
    lineHeight: "37px",
    fontWeight: "bold",
    marginLeft: "5px",
  },
  "@media(max-width: 600px)": {
    videoCount: {
      marginLeft: "2%",
    },
    watchNow: {
      marginLeft: "2%",
    },
    videoContainer: {
      marginTop: "-80em",
    },
  },
  watchNow: {
    marginTop: "45px",
    textTransform: "none",
    height: "56px",
    width: "200px",
    background: "#08C5A7",
    boxShadow: "inset 1px 1px 1px #04E8C3, inset -1px -1px 1px #07B096",
    borderRadius: "16px",
    // fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",

    /* or 18px */
    textAlign: "center",
    color: "#FFFFFF",
    "&:hover": {
      background: "#0eab8f",
    },
  },
});

const ZoomClasses = (props) => {
  // const [selectedValue, setSelectedValue] = useState(0);
  const [selectedSubject, setSelectedSubject] = useState(0);
  // const [meetingResp, setMeetingResp] = useState(null);
  const [netReq, setNetReq] = useState(false);
  const [videos, setVideos] = useState([]);
  const [zoomMtg, setZoomMtg] = useState({});
  // // function TabPanel(props) {
  // //     const { children, value, index, ...other } = props;

  // //     return (
  // //       <Typography
  // //         component="div"
  // //         role="tabpanel"
  // //         hidden={value !== index}
  // //         id={`nav-tabpanel-${index}`}
  // //         aria-labelledby={`nav-tab-${index}`}
  // //         {...other}
  // //       >
  // //         {value === index && <Box p={3}>{children}</Box>}
  // //       </Typography>
  // //     );
  // //   }
  // handleChange = async (event, newValue) => {
  //   console.log(event, newValue);
  //   this.setState({ selectedValue: newValue });
  //   if (newValue == 1) {
  //     if (document.getElementById("zmmtg-root"))
  //       document.getElementById("zmmtg-root").style.display = "none";
  //     const val = await getZmMeetings({
  //       instituteId: store.get("user").institute || "mockInstituteId",
  //     });
  //     this.setState({ videos: val.data.payload });
  //   }
  // };

  const changeSubject = (event, subject) => {
    setSelectedSubject(subject);
  };
  const joinMeeting = async () => {
    // import { ZoomMtg }  from ;
    import("@zoomus/websdk").then(async (zoom) => {
      const ZoomMtg = await zoom.ZoomMtg;
      setNetReq(true);
      setZoomMtg(ZoomMtg);
      if (document.getElementById("zmmtg-root")) {
        document.getElementById("zmmtg-root").style.display = "block";
        document
          .getElementById("shift-here")
          .appendChild(document.getElementById("zmmtg-root"));
      } else {
        const val = document.createElement("div");
        val.setAttribute("id", "zmmtg-root");
        // document.getElementById('zmmtg-root').style.display = 'block';
        document.getElementById("shift-here").appendChild(val);
      }
      ZoomMtg.setZoomJSLib("https://source.zoom.us/1.7.8/lib", "/av");
      ZoomMtg.preLoadWasm();
      ZoomMtg.prepareJssdk();
      let subject;
      switch (selectedSubject) {
        case 0:
          subject = "M";
          break;
        case 1:
          subject = "P";
          break;
        case 2:
          subject = "C";
          break;
      }
      let meetingResp = await getZoomInteractiveMeetingDetails({
        instituteId: [
          store.get("user").institute,
          store.get("user").level,
        ].join("_"),
        subject: subject,
      });
      if (
        meetingResp &&
        meetingResp.data &&
        meetingResp.data.status === "success"
      ) {
        meetingResp = meetingResp.data.payload;
      }

      console.log(meetingResp);
      var signature = ZoomMtg.generateSignature({
        meetingNumber: meetingResp.meetingId, // 2510172637
        apiKey: meetingResp.API_KEY,
        apiSecret: "sM4jHD6hcJlizBGceOhcXYzZ1RThr62C2TTu",
        role: "0",
        success: function(res) {
          console.log(res.result);
        },
      });
      // this.setState({netReq: false})
      ZoomMtg.init({
        leaveUrl: "/interactive-classes",
        isSupportChat: true,
        // meetingInfo: [
        //   'topic',
        //   'host',
        // ],
        success: function() {
          console.log("hello");
          ZoomMtg.join({
            meetingNumber: meetingResp.meetingId, // 2510172637
            userEmail: `${store.get("user").loginIndex}@onesaz.com`,
            userName: store.get("user").name,
            signature: signature,
            apiKey: meetingResp.API_KEY,
            passWord: "",
            success() {
              ZoomMtg.showPureSharingContent({
                show: false,
              });
              // $('#nav-tool').hide();
              console.log("hello");
              console.log("join meeting success", 145);
              // $('.chat-participant-header').css({

              //     visibility: 'hidden'
              //     })
              // $('.chat-participant-header').text('Ask Questions');
              // $('.chat-participant-header').html($('.chat-participant-header').html().replace('Zoom Group Chat','Ask Questions'))
            },
            error(res) {
              console.log();
              console.log("hello", document.getElementById("zmmtg-root"));
              console.log(res, "61");
              // $('.chat-participant-header').html($('.chat-participant-header').html().replace('Zoom Group Chat','Ask Questions'))
              // document.getElementById("zmmtg-root").style.display = 'none'
            },
          });
        },
        error: function(er) {
          console.log("hello", er);
        },
      });
    });
  };
  const mountFunction = async () => {
    if (document.getElementById("zmmtg-root")) {
      document.getElementById("zmmtg-root").style.display = "block";
      document
        .getElementById("shift-here")
        .appendChild(document.getElementById("zmmtg-root"));
    } else {
      //   const val =   React.createElement(
      //         "div",
      //        {id: 'zmmtg-root'},
      //       );
      const val = document.createElement("div");
      val.setAttribute("id", "zmmtg-root");
      // document.getElementById('zmmtg-root').style.display = 'block';
      document.getElementById("shift-here").appendChild(val);
    }
    const val = await getZmMeetings({
      instituteId: store.get("user").institute || "mockInstituteId",
    });
    setVideos(val.data.payload);
  };
  useEffect(() => {
    mountFunction();
    return () => {
      if (zoomMtg && zoomMtg.hasOwnProperty("leaveMeeting")) {
        zoomMtg.leaveMeeting({ leaveUrl: window.location.href });
      }
    };
  }, [zoomMtg]);
  // async componentDidMount() {
  //    ZoomMtg.setZoomJSLib('node_modules/@zoomus/websdk/dist/lib', "/av");
  // ZoomMtg.preLoadWasm();
  // ZoomMtg.prepareJssdk();
  // const meetingResp = videoService.getMeetingDetails({
  //     instituteId: store.get('user').institute || 'mockInstituteId',
  //     subject: "P"
  // }).then(val => {
  //     const meetingResp = val.data.payload;
  //     // ZoomMtg.setZoomJSLib('https://source.zoom.us/1.7.4/lib', '/av');

  //     setTimeout(() => {

  //         var signature = ZoomMtg.generateSignature({
  //             meetingNumber: meetingResp.meetingId,
  //             apiKey: meetingResp.API_KEY,
  //             apiSecret: 'sM4jHD6hcJlizBGceOhcXYzZ1RThr62C2TTu',
  //             role: '0',
  //             success: function (res) {
  //                 console.log(res.result);
  //             }
  //         });

  //         ZoomMtg.init({
  //             leaveUrl: "/interactive-classes",
  //             isSupportChat: true,
  //             success: function () {
  //                 console.log("hello")
  //                 ZoomMtg.join(
  //                     {
  //                         meetingNumber: meetingResp.meetingId,
  //                         userName: "murali129",
  //                         signature: signature,
  //                         apiKey: meetingResp.API_KEY,
  //                         passWord: '',
  //                         success() {
  //                             // $('#nav-tool').hide();
  //                             console.log("hello")
  //                             console.log('join meeting success', 145);
  //                         },
  //                         error(res) {
  //                             console.log()
  //                             console.log("hello", document.getElementById("zmmtg-root"))
  //                             console.log(res, '61');
  //                             // document.getElementById("zmmtg-root").style.display = 'none'
  //                         }
  //                     }
  //                 )
  //             },
  //             error: function (er) {
  //                 console.log("hello", er)
  //             }

  //         });
  //     }, 1000);
  // });
  // }

  const { classes } = props;
  return (
    <Fragment>
      {/* <Header /> */}
      <div className={classes.videoBg}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12} sm={10} md={8}>
            <div id="shift-here">
              <Fragment>
                <Button className={classes.watchNow} onClick={joinMeeting}>
                  Join Live
                </Button>
                {netReq && "initializing...."}
                <br /> <br /> <br />
              </Fragment>
            </div>
          </Grid>
          <Grid item xs={12} sm={10} md={8} className={classes.videoContainer}>
            <Typography p className={classes.videoCount}>
              Previous Classes&nbsp;
              <span style={{ color: "#8E9AAF", opacity: "0.4" }}>
                {videos.length}
              </span>
            </Typography>
            {videos.map((v, index) => {
              return (
                <VideoListComponent
                  key={index}
                  topic={v.topic}
                  password={
                    v.password ? (
                      <React.Fragment>Password: {v.password}</React.Fragment>
                    ) : (
                      ""
                    )
                  }
                  to={{
                    pathname: "/play",
                    state: { play_url: v.recording_files[0].play_url },
                  }}
                />
              );
            })}
          </Grid>
        </Grid>
      </div>
      <BottomNav />
    </Fragment>
  );
};

export default withStyles(styles)(ZoomClasses);
