

import NoResultMsg from "../../resultPage/NoResultMsg";

// export default function MyClassroom() {
//   return (
//     <>
//       <ResultHeader testDisplayName="View My Class Room" path="/home-page" />
//       <NoResultMsg text="No Data Available" />
//     </>
//   );
// }
import React, { useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Grid,
  Avatar,
  Button,
  Box
  // makeStyles,
} from '@material-ui/core'
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import ResultHeader from '../../resultPage/ResultHeader'
import ReusableAccordion from '../Components/ReusableAccordion'
import TeacherProfile from './TeacherProfile'
import { useHistory } from 'react-router-dom'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  table: {
    border: 'none'
  },
  title: {
    color: '#2A2A2A',
    fontSize: '18px',
    lineHeight: '20px',
    fontWeight: 700,
    fontFamily: 'Quicksand',
    padding: '16px 0'
  },
  keyTableCell: {
    padding: '8px 16px',
    borderBottom: 'none',
    color: '#2A2A2A',
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 400,
    fontFamily: 'Quicksand',
    width: '400px'
  },
  avatarCell: {
    padding: '8px 16px',
    borderBottom: 'none',
    display: 'flex',
    alignItems: 'center'
  },
  viewButton: {
    marginLeft: 'auto',
    justifyContent: 'flex-end'
  }
})

const MyClassRoom = ({ hideTitle }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [teacherDetails, setTeacherDetails] = useState([])
  const [subjectDetails, setSubjectDetails] = useState([])
  const history = useHistory()

  useEffect(() => {
    // Mock data for teachers and subjects
    const mockTeachers = [
      {
        id: 1,
        name: 'Johnson',
        subject: 'Mathematics',
        avatar: '/images/avatar1.jpg'
      },
      {
        id: 2,
        name: 'Jane Smith',
        subject: 'Physics',
        avatar: '/images/avatar2.jpg'
      },
      {
        id: 3,
        name: 'Emily Johnson',
        subject: 'Chemistry',
        avatar: '/images/avatar3.jpg'
      },
      {
        id: 4,
        name: 'John Doe',
        subject: 'English',
        avatar: '/images/avatar4.jpg'
      }
    ]

    const mockSubjects = [
      { name: 'Mathematics' },
      { name: 'Physics' },
      { name: 'Chemistry' },
      { name: 'English' },
      { name: 'Telugu' }
    ]

    setTeacherDetails(mockTeachers)
    setSubjectDetails(mockSubjects)
  }, [])

  const handleView = teacherId => {
    history.push(`/teacher-profile/${teacherId}`)
  }

  const renderTeachersSummary = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} className={classes.title}>
        Teachers List
      </Grid>
    </Grid>
  )

  const renderSubjectsSummary = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} className={classes.title}>
        Subjects List
      </Grid>
    </Grid>
  )

  const renderTeachersListDetails = () => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {teacherDetails.map(teacher => (
          <Box key={teacher.id} style={{ marginBottom: '10px' }}>
            <Grid
              container
              spacing={2}
              alignItems='center'
              justifyContent='space-between'
            >
              <Grid item className={classes.avatarCell}>
                <Avatar style={{ marginRight: '20px' }} src={teacher.avatar} />
                <ListItemText
                  primary={teacher.name}
                  secondary={teacher.subject}
                />
              </Grid>
              <Grid item>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => handleView(teacher.id)}
                  className={classes.viewButton}
                >
                  View
                </Button>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Grid>
    </Grid>
  )

  const renderSubjectsListDetails = () => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TableContainer className={classes.table}>
          <Table>
            <TableBody>
              {subjectDetails.map((subject, index) => (
                <TableRow key={index}>
                  <TableCell
                    component='th'
                    scope='row'
                    className={classes.keyTableCell}
                  >
                    {subject.name}
                  </TableCell>
                  <TableCell
                    component='th'
                    scope='row'
                    className={classes.keyTableCell}
                  >
                    {subject.name}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )

  return (
    <div style={{ paddingTop: !isMobile && !hideTitle ? '60px' : null }}>
      {hideTitle ? null : (
        <ResultHeader testDisplayName='Class Board' path='/home-page' />
      )}

      <ReusableAccordion
        renderSummary={renderTeachersSummary}
        renderDetails={renderTeachersListDetails}
        item='teachers'
        index={0}
      />
      <ReusableAccordion
        renderSummary={renderSubjectsSummary}
        renderDetails={renderSubjectsListDetails}
        item='subjects'
        index={1}
      />
    </div>
  )
}

export default MyClassRoom
