import { Tooltip } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AppBar from "@material-ui/core/AppBar";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import Fab from "@material-ui/core/Fab";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import withWidth from "@material-ui/core/withWidth";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import dhoniImage from "assets/dhoni.png";
import onesazlogo from "assets/img/onesaz.png";
import classNames from "classnames";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import React from "react";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import store from "store";
import analyticsAxios from "../apis/analyticsAxios";
import examAxios from "../apis/examServerAxios";
import neet2021SectionBQuesNos from "../utils/neet2021SectionB";
import submitBtnDisabledDuration from "../utils/submitBtnDisableDuration";
import AlertComponent from "./AlertComponent";
import "./CustomTypePanel.css";
import ExamSummary from "./ExamSummary";
// import iconA from "assets/img/iconA.svg"
import CheckedOptions from "./IconA";
//"assets/img/one.png";
import InputIntegerPanel from "./InputIntegerPanel";
import NetworkProgress from "./NetworkProgress";
import NumbersPanel from "./NumbersPanel";
// import Box from '@material-ui/core/Box';
import { preloadImages } from "../utils/cacheImageURLs";
import MatrixInput from "./MatrixInput";
import Subjects from "./Subjects";
import CacheImg from "./CacheImg";

// import Question from './Question';
// var baseUrl = require("./nta-constants").baseUrl;
var analyticsConstantData = require("./nta-constants").analyticsConstantData;
// var examServerUrl = require('./nta-constants').examServerUrl;
var moment = require("moment");

const styles = (theme) => ({
  imageIcon: {
    height: "100%",
  },
  iconRoot: {
    textAlign: "center",
  },
  checkedRadioOptions: {
    width: "40px",
    height: "40px",
  },
  radioOptions: {
    width: "40px",
    height: "40px",
    backgroundColor: "#e94733", //'#eb5944' ,//'#64cc82', //'#72e192', //'#2aabe2',
    color: "white",
  },
  radio: {
    padding: "4px",
    color: "black",
    boxShadow:
      "0px 0px 0px 0px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
    //  boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
    "&$checked": {
      // background: 'linear-gradient(60deg,#5db85c, #5db85c)',
      background: "linear-gradient(45deg, #5db85c 30%, #5db85c 90%)",
      color: "#fff",
    },
  },
  checked: {
    backgroundColor: "linear-gradient(60deg,#5db85c, #5db85c)",
    color: "#535d8c",
  },
  formControl: {
    margin: theme.spacing.unit * 3,
  },
  expired: {
    background: "red",
    borderRadius: "25px",
    color: "white",
    fontWeight: "600",
  },
  btnAttempt: {
    //   maxWidth: 'max-content',
    // marginLeft: '15%',
    //minwidth: "90%",
  },
  avatar: {
    margin: 10,
  },
  group: {
    margin: theme.spacing.unit * 1,
  },
  labelMargin: {
    marginBottom: theme.spacing.unit * 1,
  },
  btndefault: {
    border: "1px solid grey",
    color: "#333",
    backgroundColor: "#fff",
    borderColor: "#ccc",
  },
  btnreview: {
    color: "#fff",
    backgroundColor: "#337ab7",
    borderColor: "#2e6da4",
  },
  btnwarning: {
    color: "#fff",
    backgroundColor: "#f0ad4e",
    borderColor: "#eea236",
  },
  btnsuccess: {
    color: "#fff",
    // backgroundColor: '#5cb85c',
    background: "linear-gradient(45deg, #5db85c 30%,#5db85c 90%)",
    borderColor: "#4cae4c",
  },
  btnSubmit: {
    background: "green",
    color: "white",
  },
  button: {
    margin: theme.spacing.unit * 1,
  },
  optionColor: {
    color: "#31456A",
  },
  input: {
    display: "none",
  },
  bigAvatar: {
    marginTop: -10,
  },
  root: {
    flexGrow: 1,
  },
  profile: {
    flexGrow: 4,
  },
  subjectBar: {
    // height: theme.spacing(8)
  },
  paper: {
    padding: theme.spacing.unit * 4,
    textAlign: "center",
    // color: theme.palette.text.secondary,
  },
  gridsep: {
    minHeight: theme.spacing.unit * 3,
  },
  newgridsep: {
    minHeight: theme.spacing.unit * 0.5,
  },
  mathDisplay: {
    display: "none",
  },
});

function limitQuestionsToBeAnswered(
  subject,
  counterSign,
  questionType,
  questionNumber,
  isNeet = false //2021
) {
  let integerCount = store.get(subject);

  console.log(integerCount, questionType, "neet_2021");
  let { qnums } = integerCount[questionType]
    ? integerCount[questionType]
    : { qnums: {} };
  if (counterSign === "increment") qnums[questionNumber] = questionNumber;
  else if (!isNeet) delete qnums[questionNumber];
  console.log(qnums, "from hari 168", subject);
  store.set(subject, {
    [questionType]: {
      qnums: { ...qnums },
    },
  });
  // let { count } = integerCount[questionType];

  // counterSign === "increment"
  //   ? count < 5
  //     ? count++
  //     : (count = 5)
  //   : count > 0
  //   ? count--
  //   : (count = 0);
  // store.set(subject, {
  //   [questionType]: {
  //     count,
  //   },
  // });
}
// ------call sync time spent function after every 5 min-----
const callUpdateTimeSpentApi = (student_id, test_name) => {
  examAxios
    .post("/exams/updatetimespent", {
      student_id,
      test_name,
      timeSpent: store.get("timeSpentObj") || {},
    })
    .then((res) => {
      if (res.data && res.data.status === "SUCCESS") {
        store.set("timeSpentObj", {});
      }
    })
    .catch((error) => console.log(error));
};

// ------------------------------------------------------
class CustomTypePanel extends React.Component {
  timer;
  syncTimeSpent;
  submitBtnMsg = "You can submit the exam only before 30 mins of the end time.";
  statusdisplay = {
    NOT_VISITED: 0,
    NOT_ANSWERED: 0,
    ANSWERED: 0,
    MARKED_FOR_REVIEW: 0,
    ANSWERED_MARKED_FOR_REVIEW: 0,
  };
  isSubmitted = false;
  a = 4;
  time_started;
  remainingTime = Date.now();
  displayTime = 0;
  expandfromleft = {
    left: "90%",
    display: "unset",
    xsLeft: 9,
    xsRight: 3,
    isExpanded: false,
    icon: <KeyboardArrowRightIcon />,
  };
  q = Array(90)
    .fill(0)
    .map((val, index) => index + 1);
  questions = [];
  studentAnswers = [];
  constructor(props) {
    console.log("homepanel asdfasd");
    console.log(props);
    super(props);
    this.state = {
      current_question: {},
      questions: [],
      confirmOpen: false,
      alertText: "",
      alertType: "",
      confirmationtType: "SUMMARY",
      ifImagesLoading: true,
      imageURLs: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
    this.setIntegerValue = this.setIntegerValue.bind(this);
  }
  handleChange(event) {
    let update_c_q = this.state.current_question;
    update_c_q.markedAnswer = event.target.value;
    this.setState({ current_question: update_c_q });
    // analytics
    //this.analyticsUPStream(update_c_q.question_number, "OPTION_SELECTION", update_c_q.status, update_c_q.markedAnswer, update_c_q.subject);
    // end of analytics
  }
  handleCloseAlertBox = () => {
    this.setState({ confirmOpen: false, alertText: "" });
  };
  clear() {
    const { q_type, subject, status } = this.state.current_question;
    const update_c_q = this.state.current_question;
    update_c_q.markedAnswer = "NA";
    update_c_q.status = "NOT_ANSWERED";
    let update_opts = {
      method: "post",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({
        student_id: this.props.user,
        test_name: this.props.examName,
        question_number: update_c_q.question_number,
        question_state: update_c_q.status,
        status: update_c_q.status,
        question_answer: 1,
        markedAnswer: update_c_q.markedAnswer,
        endDateTime: this.props.location.state.endDateTime,
      }),
    };
    // let  updateUrl = examServerUrl+ '/exams/updateanswer';
    //     fetch(updateUrl,update_opts).catch( err =>{
    //         //handling
    //         console.log("err in clearing..",err)
    //     }).then(results=>results.json()).then( res => {
    //         console.log(res.status)
    //     }
    //     )

    examAxios
      .post("/exams/updateanswer", update_opts.body)
      .catch((err) => {
        //handling
        console.log("err in clearing..", err);
      })
      .then((results) => results.data)
      .then((res) => {
        if (q_type === "integer") {
          limitQuestionsToBeAnswered(
            subject,
            "decrement",
            "integer",
            update_c_q.question_number
          );
        }
        // neet restriction
        console.log(neet2021SectionBQuesNos, "neet_2021");
        if (
          this.props.test_type === "NEET_2021" &&
          neet2021SectionBQuesNos[subject].indexOf(
            update_c_q.question_number
          ) >= 0
        ) {
          limitQuestionsToBeAnswered(
            subject,
            "decrement",
            q_type ? q_type : "single",
            update_c_q.question_number,
            true
          );
        }
      });
    // Analytics
    //this.analyticsUPStream(update_c_q.question_number, "CLEAR_SELECTION", "NOT_ANSWERED",update_c_q.subject);
    // end of analytics
    this.setState({ current_question: update_c_q });

    // update_c_q.markedAnswer= null;
  }
  async handlePendingAnswers() {
    store.each(async (val, key) => {
      if (!isNaN(key)) {
        await examAxios
          .post("/exams/updateanswer", val)
          .catch((err) => {
            // handle error
            // this.questions[index - 1].status = prev_status;
            // this.studentAnswers[index - 1].status = prev_status;
            // console.log('error in saving the markedanswer')
          })
          .then((results) => {
            if (results) return results.data;
          })
          .then((res) => {
            // console.log(res.status)
            store.remove(key);
          });
      }
    });
  }
  submit(val = "submit") {
    console.log(val, this.props);
    const { startDateTime, endDateTime } = this.props.location.state;
    Object.keys(this.statusdisplay).forEach((key) => {
      this.statusdisplay[key] = 0;
    });
    this.questions.forEach((question) => {
      this.statusdisplay[question.status] += 1;
    });
    switch (val) {
      case "YES":
        let body = JSON.stringify({
          student_id: this.props.user,
          test_name: this.props.examName,
          code: this.props.code,
          startDateTime: startDateTime,
          endDateTime: endDateTime,
        });
        // Upload the pending items to server
        this.setState({ confirmationtType: "PENDING_ITEMS" });
        this.forceUpdate();
        this.handlePendingAnswers();
        // then submit exam
        examAxios.post("/exams/submittest", body).then((val) => {
          console.log(val);
          this.setState({ confirmationtType: "RESULT" });
          callUpdateTimeSpentApi(this.props.user, this.props.examName);
          clearInterval(this.timer);
          Object.keys(this.state.qNumPerSubject).forEach((subject) => {
            store.remove(subject);
          });
          this.remainingTime = -1;
          this.isSubmitted = true;
          this.forceUpdate();
          examAxios
            .post("/exams/results", body)
            .then((respResults) => {
              console.log(respResults);
            })
            .catch((err) => {
              console.log(err);
            });
        });
        break;
      case "NO":
        this.isSubmitted = false;
        break;
      case "submit":
        console.log(val);
        this.analyticsUPStream(
          this.state.current_question.question_number,
          "EXIT",
          this.state.current_question.status,
          this.state.current_question.markedAnswer,
          this.state.current_question.subject
        );
        this.isSubmitted = true;
        break;
      case "VIEW_RESULT":
        console.log("view");
        const { history, examName, test_type, location } = this.props;
        history.push({
          pathname: `/${examName}/new-result`,
          search: `?test=${location.state.testDisplayName}&type=${test_type}`,
        });
        this.forceUpdate();
        break;
    }
    this.forceUpdate();
  }
  attempt(status, index, noskip = true) {
    // console.log(this.state.current_question)
    if (
      (noskip && !this.state.current_question.markedAnswer) ||
      (this.state.current_question.markedAnswer == "NA" &&
        status !== "MARKED_FOR_REVIEW")
    ) {
      this.setState({
        confirmOpen: true,
        alertText: "Please choose an option",
      });
      return;
    }
    let prev_status = this.questions[index - 1].status;
    this.questions[index - 1].status = status;
    this.studentAnswers[index - 1].status = status;
    if (index > this.questions.length) {
      this.forceUpdate();
      return;
    }
    console.log(this.questions[index - 1], index);
    let update_c_q = this.questions[index - 1];
    let update_opts = {
      method: "post",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({
        student_id: this.props.user,
        test_name: this.props.examName,
        question_number: update_c_q.question_number,
        question_state: update_c_q.status,
        status: update_c_q.status,
        question_answer: 1,
        markedAnswer: update_c_q.markedAnswer,
        endDateTime: this.props.location.state.endDateTime,
      }),
    };
    // let  updateUrl = examServerUrl+ '/exams/updateanswer';
    store.set(this.questions[index - 1].question_number, update_opts.body);
    store.each((val, key) => {
      console.log(key, ":", val);
    });
    examAxios
      .post("/exams/updateanswer", update_opts.body)
      .then((results) => {
        if (results) {
          if (results.data.status === "SUCCESS") {
            const { q_type, subject, status } = this.questions[index - 1];
            if (q_type === "integer" && status !== "MARKED_FOR_REVIEW") {
              limitQuestionsToBeAnswered(
                subject,
                "increment",
                "integer",
                update_c_q.question_number
              );
            }

            // console.log(neet2021SectionBQuesNos, "neet_2021", neet2021SectionBQuesNos[subject].includes(
            //   update_c_q.question_number
            // ), update_c_q)
            // neet restriction
            if (
              this.props.test_type === "NEET_2021" &&
              neet2021SectionBQuesNos[subject].indexOf(
                update_c_q.question_number
              ) >= 0 &&
              status !== "MARKED_FOR_REVIEW"
            ) {
              limitQuestionsToBeAnswered(
                subject,
                "increment",
                q_type ? q_type : "single",
                update_c_q.question_number,
                true
              );
            }
          }
          return results.data;
        }
      })
      .then((res) => {
        // console.log(res.status)
        store.remove(this.questions[index - 1].question_number);
      })
      .catch((error) => {
        this.setState({
          confirmOpen: true,
          alertText: `Failed to save the answer of Question No: "${index}", please check your internet correction and try again.`,
        });
        this.questions[index - 1].status = prev_status;
        this.studentAnswers[index - 1].status = prev_status;
      });
    if (index >= this.questions.length) return;
    if (this.questions[index].status == "NOT_VISITED") {
      this.questions[index].status = "NOT_ANSWERED";
      this.studentAnswers[index].status = "NOT_ANSWERED";
    }

    //====== for analytics ====

    // this.analyticsUPStream(update_c_q.question_number, "EXIT", update_c_q.status, update_c_q.markedAnswer);
    // end of analytics
    // this.setState({ current_question: this.questions[index], questions: this.questions });
    this.forceUpdate();
    this.displayQuestion(index + 1);
    // this.analyticsUPStream(current_question.question_number, "DISPLAY", current_question.status, current_question.markedAnswer);
  }
  displayQuestion(index, status = "NOT_ANSWERED") {
    if (index < 1 || index > this.state.questions.length) {
      return;
    }
    let update_c_q = {};
    update_c_q = this.questions[index - 1];
    // console.log(this.questions[index - 1],index);
    if (this.questions[index - 1].status == "NOT_VISITED") {
      this.questions[index - 1].status = status;
      // this has to change only when it is not visited
      console.log(this.studentAnswers[index - 1], this.questions[index - 1]);
      this.studentAnswers[index - 1].status = status;
      //
      // updating the not_answered state
      //

      let update_opts = {
        method: "post",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({
          student_id: this.props.user,
          test_name: this.props.examName,
          question_number: update_c_q.question_number,
          question_state: update_c_q.status,
          status: update_c_q.status,
          question_answer: 1,
          markedAnswer: update_c_q.markedAnswer,
          endDateTime: this.props.location.state.endDateTime,
        }),
      };
      // let  updateUrl = examServerUrl+ '/exams/updateanswer';
      examAxios
        .post("/exams/updateanswer", update_opts.body)
        .catch((err) => {
          // handle error
          console.log("error in saving the not_answered state");
        })
        .then((results) => results.data)
        .then((res) => {
          // console.log(res.status)
        });

      //  end of update
    }
    if (this.state.current_question)
      this.analyticsUPStream(
        this.state.current_question.question_number,
        "EXIT",
        this.state.current_question.status,
        this.state.current_question.markedAnswer,
        this.state.current_question.subject
      );
    // analytics
    this.analyticsUPStream(
      update_c_q.question_number
        ? update_c_q.question_number
        : this.questions[index - 1].question_number,
      "DISPLAY",
      update_c_q.status,
      update_c_q.markedAnswer,
      update_c_q.subject
    );
    // end of analytics
    //    console.log(this.state.current_question)
    this.setState({ current_question: this.questions[index - 1], ci: index });
    //  console.log(this.state.current_question)
    this.forceUpdate();
  }
  componentDidMount() {
    const { history } = this.props;
    let queryData = {
      student_id: this.props.user,
      test_name: this.props.examName,
    };
    this.syncTimeSpent = setInterval(() => {
      callUpdateTimeSpentApi(queryData.student_id, queryData.test_name);
    }, 180000);
    examAxios
      .post("/exams/writetest", queryData)
      .then((results) => results.data)
      .then((value) => {
        console.log("value", value);
        if (value.payload.test_status == "FINISHED") {
          // alert("hello123","value")
          history.push("/home");
          return;
        }
        let qNumPerSubject = {};
        this.questions = value.payload.Items.map((item) => {
          if (!item.status) {
            item.status = "NOT_VISITED";
          }
          if (qNumPerSubject[item.subject] == undefined) {
            qNumPerSubject[item.subject] = 1;
          } else {
            qNumPerSubject[item.subject] += 1;
          }

          if (
            (item.A == "NA" &&
              item.B == "NA" &&
              item.C == "NA" &&
              item.D == "NA") ||
            (!item.A && !item.B && !item.C && !item.D)
          ) {
            item.q_type = "integer";
          }
          return item;
        });

        // set integer question count as 5;
        const subjectQnumsLimit = {};
        Object.keys(qNumPerSubject).forEach((subject) => {
          let integerCounter = store.get(subject);
          subjectQnumsLimit[subject] = { qnums: {} };
          if (integerCounter) {
            store.get(subject);
          } else {
            if (this.props.test_type === "NEET_2021") {
              store.set(subject, {
                single: { qnums: {} },
              });
            } else {
              store.set(subject, {
                integer: { qnums: {} },
              });
            }
          }
        });
        //neet 2021 restrictions

        // for codes A_D
        if (value.payload.code !== undefined) {
          const examCode = "Code" + value.payload.code;
          if (value.payload[examCode] !== undefined) {
            const codeQuestionsOrder = value.payload[examCode].split(",");
            const rearrangeQuestions = [];
            codeQuestionsOrder.forEach((q_num) => {
              rearrangeQuestions.push(
                ...this.questions.filter((q) => q.question_number == q_num)
              );
            });
            this.questions = rearrangeQuestions;
          }
        }
        console.log(this.questions);
        // let examUrl = examServerUrl+"/exams/studentanswers";
        let opts = {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            student_id: this.props.user,
            test_name: this.props.examName,
          }),
        };
        let val = JSON.stringify(opts);
        console.log(val);
        examAxios
          .post("/exams/studentanswers", opts.body)
          .then((res) => res.data)
          .then((res) => {
            this.studentAnswers = res.payload.Items[0].answers;

            let reArrangeStudentAnswers = []; // this is as per question order;
            this.questions.forEach((q) => {
              reArrangeStudentAnswers.push(
                ...this.studentAnswers.filter(
                  (val) => val.question_number == q.question_number
                )
              );
            });
            if (this.questions.length > 0) {
              console.log(reArrangeStudentAnswers);

              this.studentAnswers = reArrangeStudentAnswers;
              this.studentAnswers = this.studentAnswers.map((answer, index) => {
                if (!answer.status) {
                  answer.status = "NOT_VISITED";
                }
                this.questions[index].status = answer.status;
                this.questions[index].markedAnswer = answer.markedAnswer;
                console.log(this.questions[index].markedAnswer, "markedAnswer");
                // console.log(this.questions, this.studentAnswers)
                // c
                if (
                  (this.questions[index].q_type == "integer" ||
                    (this.props.test_type === "NEET_2021" &&
                      neet2021SectionBQuesNos[
                        this.questions[index].subject
                      ].indexOf(this.questions[index].question_number) >= 0 &&
                      subjectQnumsLimit[this.questions[index].subject])) &&
                  this.questions[index].markedAnswer != "NA" &&
                  this.questions[index].status != "MARKED_FOR_REVIEW"
                )
                  subjectQnumsLimit[this.questions[index].subject].qnums = {
                    ...subjectQnumsLimit[this.questions[index].subject].qnums,
                    [this.questions[index].question_number]: this.questions[
                      index
                    ].question_number,
                  };

                return answer;
              });
              //

              Object.keys(qNumPerSubject).forEach((subject) => {
                if (this.props.test_type === "NEET_2021") {
                  store.set(subject, {
                    single: { qnums: subjectQnumsLimit[subject].qnums },
                  });
                } else {
                  store.set(subject, {
                    integer: { qnums: subjectQnumsLimit[subject].qnums },
                  });
                }
              });
              // ############ pre load images to cache it ###############

              preloadImages(this.questions)
                .then((res) => {
                  this.setState({
                    ifImagesLoading: false,
                    imageURLs: res,
                  });
                  console.log(`${res.length} images pre-loaded`);
                })
                .catch((error) => {
                  this.setState({
                    ifImagesLoading: false,
                  });
                  console.error("caching error:798", error);
                });
              // ########################################################
              this.setState({
                current_question: this.questions[0],
                questions: this.questions,
                qNumPerSubject: qNumPerSubject,
              });
              this.displayQuestion(1);
            }
          })
          .catch((err) => {
            console.log(err);
          });

        let res = value;
        // console.log(res);
        //  store.set("startedTime",res.payload.test_started);
        //  store.set("duration",res.payload.test_duration);
        this.time_started = res.payload.time_started;

        if (
          window.location.host.includes("sr.onesaz") ||
          window.location.host.includes("tirumala.onesaz") ||
          window.location.host == "onesaz" ||
          window.location.host.includes("localhost") ||
          (window.location.host.includes("bhashyam.onesaz") &&
            store.get("user").level == "2119611")
        ) {
          //  console.log(moment(res.payload.endDateTime).format("x"));
          const endDateTime = Number(
            moment(res.payload.endDateTime).format("x")
          );
          this.remainingTime = Number(
            moment(endDateTime)
              .subtract(res.payload.time_check)
              .format("x")
          );
          // console.log(
          //   res.payload.time_started,
          //   endDateTime,
          //   this.remainingTime
          // );
          // this.remainingTime =
          // Number(this.props.examDuration) +
          // Number(
          //   moment(res.payload.time_started)
          //     .subtract(res.payload.time_check)
          //     .format("x")
          // );
        } else {
          this.remainingTime =
            Number(res.payload.test_duration || this.props.examDuration) +
            Number(
              moment(res.payload.time_started)
                .subtract(res.payload.time_check)
                .format("x")
            );
        }
        this.timer = setInterval(() => {
          this.remainingTime = this.remainingTime - 1000;
          // console.log(res.payload.test_duration,res.payload.time_started , Date.now(),res.payload)
          // console.log(this.remainingTime, res.payload, this.props.examDuration)
          if (this.remainingTime < 0) {
            clearInterval(this.timer);
            // history.push('/not-found');
            this.displayTime = 0;
            //alert("Exam timeline finished");
            this.setState({
              confirmOpen: true,
              alertText: "Exam timeline finished",
            });
            this.submit("YES");
            const { examName, test_type, location } = this.props;
            history.push({
              pathname: `/${examName}/new-result`,
              search: `?test=${
                location.state.testDisplayName
              }&type=${test_type}`,
            });
          } else {
            const qNo = this.state.current_question.question_number;
            let timeSpentObj = store.get("timeSpentObj");
            if (!timeSpentObj) {
              timeSpentObj = {};
            }
            timeSpentObj[qNo] = timeSpentObj[qNo] ? timeSpentObj[qNo] + 1 : 1;
            // time mapping for each question
            store.set("timeSpentObj", timeSpentObj);
            //  this.remainingTime =  this.remainingTime > 0 ? this.remainingTime : 0;
            // console.log(this.remainingTime);
            // console.log( Number(this.props.examDuration) + Number(moment(res.payload.time_started).subtract(1574597443788).format('x')))
            const seconds = this.remainingTime / 1000;
            const m = parseInt(seconds / 60) + ""; // Calculate minutes
            const s = parseInt(seconds % 60) + ""; // Calculate remaining seconds
            this.displayTime = [
              m.padStart(2, "0"),
              s.padStart(2, "0"),
            ].join(":");
            // this.displayTime = [moment(this.remainingTime).get("h"), moment(this.remainingTime).get("m"), moment(this.remainingTime).get("s")].join(":");
            // console.log(moment(res.payload.time_started).format('hh:mm:ss a'), moment().format('hh:mm:ss a'), this.remainingTime);
          }
          this.forceUpdate();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 429) {
          this.setState({
            confirmOpen: true,
            alertText:
              "Too many reload requests, please try reloading after 1 min.",
          });
        }
      })
      .finally(() => {
        // alert("no internet connection")
      });
  }

  expandQuestion() {
    if (this.expandfromleft.isExpanded) {
      let expandfromleft = {
        left: "90%",
        display: "unset",
        xsLeft: 9,
        xsRight: 3,
        isExpanded: false,
        icon: <KeyboardArrowRightIcon />,
      };
      this.expandfromleft = expandfromleft;
    } else {
      let expandfromleft = {
        left: "93%",
        display: "none",
        xsLeft: 12,
        xsRight: 0,
        isExpanded: true,
        icon: <KeyboardArrowLeftIcon />,
      };
      this.expandfromleft = expandfromleft;
    }
  }

  componentDidUpdate() {
    const math = document.getElementById("student-math");
    // console.log("hhhhh", "examscreen")

    //  window.MathJax.typeset();
    if (math)
      window.MathJax.Hub.Queue([
        "Typeset",
        window.MathJax.Hub,
        math,
        () => {
          // console.log(math,math.style.visibility)
          // math.style.visibility = "";
          //  console.log(math,math.style.visibility)
        },
      ]);
  }

  analyticsUPStream(q_no, type, status, markedAnswer = "NA", subject) {
    let danalyticsData = {
      ...analyticsConstantData,
      Data: {
        exam_name_and_student_id: [this.props.examName, this.props.user].join(
          "_"
        ),
        question_number: q_no,
        action: {
          type: type,
          status: status,
          markedAnswer: markedAnswer,
          subject: subject,
          timestamp: Date.now(),
        },
      },
    };
    analyticsAxios
      .post("", danalyticsData)
      .catch((err) => {
        console.log("error in posting data to upstream");
      })
      .then((res) => {
        // console.log(res);
      });
  }

  setIntegerValue(value) {
    this.state.current_question.markedAnswer = value;
  }
  //handle matrix values
  setMatrixValues = (matrixVal) => {
    this.state.current_question.markedAnswer = matrixVal;
  };
  handleMultiple = (option) => (event) => {
    console.log(
      option,
      this.state.current_question.markedAnswer,
      event.target.checked
    );
    if (this.state.current_question.markedAnswer == "NA") {
      this.state.current_question.markedAnswer = "";
    }
    if (event.target.checked) {
      if (this.state.current_question.markedAnswer.includes(option)) {
      } else {
        this.state.current_question.markedAnswer += option;
        this.forceUpdate();
      }
    } else {
      if (this.state.current_question.markedAnswer.includes(option)) {
        this.state.current_question.markedAnswer = this.state.current_question.markedAnswer.replace(
          option,
          ""
        );
        this.forceUpdate();
      }
    }

    // this.state.current_question.markedAnswer += option;
  };
  isChecked = (option) => {
    if (this.state.current_question.markedAnswer == "NA") {
      return false;
    }
    return this.state.current_question.markedAnswer.includes(option);
  };

  componentWillUnmount() {
    clearInterval(this.syncTimeSpent);
  }
  render() {
    // this.props.classes.mathDisplay = "none";
    const { classes, test_type } = this.props;
    console.log(this.state, "render");
    let html_current_question = {};
    const options = ["A", "B", "C", "D", "E"];
    const { current_question } = this.state;
    const { host } = window.location;
    const user = store.get("user");
    if (this.state.current_question) {
      html_current_question.question_number = this.state.current_question.question_number;
      html_current_question.q = (
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          <div
            dangerouslySetInnerHTML={{ __html: this.state.current_question.q }}
          />
        </Grid>
      );
      options.forEach((option) => {
        if (this.state.current_question[option]) {
          html_current_question[option] = (
            <span
              className={classes.optionColor}
              dangerouslySetInnerHTML={{
                __html: this.state.current_question[option],
              }}
            />
          );
        }
      });
      if (
        this.state.current_question.paragraph !== undefined &&
        this.state.current_question.paragraph !== "NA"
      ) {
        html_current_question.paragraph = (
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            className={classes.textWrapper}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.current_question.paragraph,
              }}
            />
          </Grid>
        );
      }
    }
    // const hiddenflag = "hidden";
    // console.log(hiddenflag)
    let val = (
      <React.Fragment>
        <Typography
          component="div"
          className="qpanel"
          style={{
            padding: "0px 24px",
            minWidth: "100%",
            maxWidth: "100%",
          }}
        >
          <div>
            <h5 className="question"> Question({this.state.ci})</h5>
          </div>
          {html_current_question.paragraph !== undefined && (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>Paragraph</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component="div">
                  {html_current_question.paragraph}
                </Typography>
              </AccordionDetails>
            </Accordion>
          )}
          <Divider variant="middle" />
          {html_current_question.q}
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend" />
            {test_type === "NEET_2021" &&
            current_question.subject &&
            (current_question.status === "NOT_ANSWERED" ||
              current_question.status === "MARKED_FOR_REVIEW") &&
            store.get(current_question.subject) &&
            store.get(current_question.subject).single.qnums &&
            (!store.get(current_question.subject).single.qnums[
              current_question.question_number
            ] &&
              neet2021SectionBQuesNos[current_question.subject].indexOf(
                current_question.question_number
              ) >= 0 &&
              Object.keys(store.get(current_question.subject).single.qnums)
                .length >= 10) ? (
              <Typography color="error">
                Only 10 out of 15 questions Allowed to Attempt in this section.
                To answer this clear one answered question and attempt this.
              </Typography>
            ) : (
              <RadioGroup
                className={classes.group}
                value={
                  this.state.current_question.markedAnswer == undefined
                    ? ""
                    : this.state.current_question.markedAnswer
                }
                onChange={this.handleChange}
              >
                {options.map((option) => {
                  //console.log(this.state.current_question.markedAnswer,option);
                  if (html_current_question[option]) {
                    return (
                      <FormControlLabel
                        className={classes.labelMargin}
                        checked={
                          this.state.current_question.markedAnswer == option
                        }
                        key={
                          html_current_question.question_number + "_" + option
                        }
                        value={option}
                        control={
                          <Radio
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                            icon={
                              <CheckedOptions option={option}> </CheckedOptions>
                            }
                            checkedIcon={
                              <CheckedOptions
                                style={{ fontSize: "10px" }}
                                option={option}
                              >
                                {" "}
                              </CheckedOptions>
                            }
                          />

                          //  <Radio classes={{ root: classes.radio, checked: classes.checked }} />
                        }
                        label={
                          <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                          >
                            {/* {option}) */}
                            {/* <Fab  className={ this.state.current_question.markedAnswer == option? classes.radioOptions: classes.checkedRadioOptions} >{option} </Fab>   */}
                            &nbsp; &nbsp;&nbsp; &nbsp;{" "}
                            {html_current_question[option]}
                          </Grid>
                        }
                      />
                    );
                  }
                  return null;
                })}
              </RadioGroup>
            )}
          </FormControl>
        </Typography>
        <Typography
          component="div"
          style={{
            top: "24em",
            left: this.expandfromleft.left,
            position: "absolute",
            zIndex: "1",
            display: this.props.width !== "lg" ? "none" : "unset",
          }}
        >
          <Fab
            onClick={() => {
              this.expandQuestion();
              this.forceUpdate();
            }}
          >
            {this.expandfromleft.icon}
          </Fab>
        </Typography>
      </React.Fragment>
    );
    // console.log(
    //   this.props.test_type,
    //   ["23", "24"].indexOf(html_current_question.question_number),
    //   html_current_question.question_number
    // );
    if (
      this.state.current_question.q_type &&
      ["integer", "float", "integer_p", "float_p"].includes(
        this.state.current_question.q_type
      ) &&
      (this.props.test_type == "JEE MAINS" ||
        this.props.test_type == "JEEMAIN_2021" ||
        this.props.test_type == "JEEMAIN_2022" ||
        this.props.test_type === "CUSTOM") &&
      this.state.questions &&
      this.state.questions.length > 0
    ) {
      val = (
        <React.Fragment>
          <Typography
            component="div"
            className="qpanel"
            style={{
              padding: "0px 24px",
              minWidth: "100%",
              maxWidth: "100%",
            }}
          >
            <div>
              <h5 className="question"> Question({this.state.ci})</h5>
            </div>
            {html_current_question.paragraph !== undefined && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>Paragraph</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography component="div">
                    {html_current_question.paragraph}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            )}
            <Divider variant="middle" />
            {html_current_question.q}
            <GridContainer direction="row" justify="center">
              <GridItem xs={6} sm={4}>
                {current_question.subject &&
                (current_question.status === "NOT_ANSWERED" ||
                  current_question.status === "MARKED_FOR_REVIEW") &&
                store.get(current_question.subject) &&
                store.get(current_question.subject).integer.qnums &&
                (!store.get(current_question.subject).integer.qnums[
                  current_question.question_number
                ] &&
                  Object.keys(store.get(current_question.subject).integer.qnums)
                    .length >= 5) ? (
                  <Typography color="error">
                    Only Allowed to Attempt 5 out of 10 integer questions. To
                    answer this clear one answered question and attempt this.
                  </Typography>
                ) : (
                  <InputIntegerPanel
                    markedanswer={
                      this.state.current_question.markedAnswer == "NA"
                        ? ""
                        : this.state.current_question.markedAnswer
                    }
                    setIntegerValue={this.setIntegerValue}
                    q_type={this.state.current_question.q_type}
                  />
                )}
              </GridItem>
            </GridContainer>
          </Typography>
          <Typography
            component="div"
            style={{
              top: "24em",
              left: this.expandfromleft.left,
              position: "absolute",
              zIndex: "1",
              display: this.props.width !== "lg" ? "none" : "unset",
            }}
          >
            <Fab
              onClick={() => {
                this.expandQuestion();
                this.forceUpdate();
              }}
            >
              {this.expandfromleft.icon}
            </Fab>
          </Typography>
        </React.Fragment>
      );
    }
    if (
      this.state.current_question.q_type &&
      ["list", "matrix", "list_p", "matrix_p"].includes(
        this.state.current_question.q_type
      )
    ) {
      val = (
        <React.Fragment>
          <Typography
            component="div"
            className="qpanel"
            style={{
              whiteSpace: "nowrap",
              padding: "0px 24px",
              minWidth: "100%",
              maxWidth: "100%",
            }}
          >
            {html_current_question.paragraph !== undefined && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>Paragraph</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography component="div">
                    {html_current_question.paragraph}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            )}
            <Divider variant="middle" />
            <div>
              <h5 className="question">
                Question({html_current_question.question_number})
              </h5>
            </div>

            {html_current_question.q}
            {
              <MatrixInput
                markedAnswer={
                  this.state.current_question.markedAnswer == "NA"
                    ? {}
                    : this.state.current_question.markedAnswer
                }
                setMatrixValues={this.setMatrixValues}
              />
            }
          </Typography>
          <Typography
            component="div"
            style={{
              top: "24em",
              left: this.expandfromleft.left,
              position: "absolute",
              zIndex: "1",
              display: this.props.width !== "lg" ? "none" : "unset",
            }}
          >
            <Fab
              onClick={() => {
                this.expandQuestion();
                this.forceUpdate();
              }}
            >
              {this.expandfromleft.icon}
            </Fab>
          </Typography>
        </React.Fragment>
      );
    }
    if (
      this.state.current_question.q_type &&
      [
        "multiple",
        "partial",
        "multiple_p",
        "partial_p",
        "single_partial",
        "assertion",
        "assertion_p",
      ].includes(this.state.current_question.q_type)
    ) {
      val = (
        <React.Fragment>
          <Typography
            component="div"
            className="qpanel"
            style={{
              padding: "0px 24px",
              minWidth: "100%",
              maxWidth: "100%",
            }}
          >
            {html_current_question.paragraph !== undefined && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>Paragraph</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography component="div">
                    {html_current_question.paragraph}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            )}
            <Divider variant="middle" />
            <div>
              {" "}
              <h5 className="question">
                {" "}
                Question({html_current_question.question_number})
              </h5>
            </div>
            {html_current_question.q}
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend" />
              <FormGroup
                className={classes.group}
                //value={this.state.current_question.markedAnswer==undefined? "": this.state.current_question.markedAnswer}
                //onChange={this.handleChange}
              >
                {options.map((option) => {
                  if (html_current_question[option]) {
                    return (
                      <FormControlLabel
                        className={classes.labelMargin}
                        checked={
                          this.state.current_question.markedAnswer == option
                        }
                        key={
                          html_current_question.question_number + "_" + option
                        }
                        value={option}
                        control={
                          <Checkbox
                            checked={this.isChecked(option)}
                            onChange={this.handleMultiple(option)}
                            c
                            lasses={{
                              root: classes.multipleradio,
                              checked: classes.multiplechecked,
                            }}
                          />
                        }
                        label={
                          <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                          >
                            &nbsp; &nbsp;&nbsp; &nbsp;{" "}
                            {html_current_question[option]}{" "}
                          </Grid>
                        }
                      />
                    );
                  }
                  return null;
                })}
              </FormGroup>
            </FormControl>
          </Typography>
          <Typography
            component="div"
            style={{
              top: "24em",
              left: this.expandfromleft.left,
              position: "absolute",
              zIndex: "1",
              display: this.props.width !== "lg" ? "none" : "unset",
            }}
          >
            <Fab
              onClick={() => {
                this.expandQuestion();
                this.forceUpdate();
              }}
            >
              {this.expandfromleft.icon}
            </Fab>
          </Typography>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <div className={classes.root}>
          <AppBar position="static" color="inherit">
            <Toolbar direction="row" justify="flex-end">
              <Hidden smDown>
                <img
                  style={{ width: "150px", height: 45 }}
                  src={onesazlogo}
                  alt="..."
                />

                {/* <Box my={4} className={classes.root} style={{margin:'0px'}}>
                                  <span> <img src="./b_logo.png" width="174px" height="70px" alt="" />
                                    </span> 
                            </Box> */}
                {/* <Box my={4} className={classes.profile}>
                                
                            </Box> */}
                <GridContainer
                  className={classes.root}
                  justify="flex-end"
                  style={{ marginRight: "8%" }}
                >
                  <table>
                    <tbody>
                      <tr>
                        {/* <tcell> */}
                        {/* <tr> */}
                        <td
                          style={{
                            verticalAlign: "middle",
                            padding: 10,
                          }}
                        >
                          <span>
                            <img
                              src={dhoniImage}
                              style={{
                                border: "2px solid grey",
                                verticalAlign: "bottom",
                              }}
                              width="60px"
                              height="60px"
                              alt=""
                            />
                          </span>
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          <span> Candidate Name </span>
                          <br />
                          <span>Test Name</span>
                          <br />
                          <span>Remaining Time</span>
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          :{" "}
                          <span
                            style={{
                              color: "orange",
                              fontWeight: "bold",
                            }}
                          >
                            {store.get("user").name}
                          </span>
                          <br />:{" "}
                          <span
                            style={{
                              color: "orange",
                              fontWeight: "bold",
                            }}
                          >
                            {" "}
                            {this.props.displayname}{" "}
                          </span>
                          <br />
                          {/* : <Timer submit={this.submit} time_started={this.time_started} examDuration={this.props.examDuration} ></Timer> */}
                          :{" "}
                          <span
                            style={{
                              color: "orange",
                              fontWeight: "bold",
                            }}
                          >
                            {" "}
                            {this.remainingTime > 0 ? (
                              <span
                                style={{
                                  background: "#0098DA",
                                  borderRadius: "25px",
                                  color: "white",
                                  fontWeight: "600",
                                  padding: "2px 4px",
                                }}
                              >
                                {this.displayTime}{" "}
                              </span>
                            ) : (
                              <span className={classes.expired}>
                                {" "}
                                &nbsp;&nbsp;expired &nbsp;&nbsp;
                              </span>
                            )}
                          </span>
                        </td>
                        {/* </tr> */}
                        {/* </tcell> */}
                      </tr>
                    </tbody>
                  </table>
                </GridContainer>
              </Hidden>

              {/*for mobile  */}
              <Hidden mdUp>
                <Grid container justify="space-between">
                  <Grid item>
                    <img
                      style={{ width: "120px", height: 35 }}
                      src={onesazlogo}
                      alt="..."
                    />
                  </Grid>

                  <Grid item>
                    <Grid container direction="column" alignItems="flex-end">
                      <Grid item>
                        <span
                          style={{
                            color: "orange",
                            fontWeight: "bold",
                          }}
                        >
                          {store.get("user").name}
                        </span>
                      </Grid>

                      <Grid item>
                        <span
                          style={{
                            color: "orange",
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                          {this.remainingTime > 0 ? (
                            <span
                              style={{
                                background: "#0098DA",
                                borderRadius: "25px",
                                color: "white",
                                fontWeight: "600",
                                padding: "2px 4px",
                              }}
                            >
                              {this.displayTime}{" "}
                            </span>
                          ) : (
                            <span className={classes.expired}>
                              {" "}
                              &nbsp;&nbsp;expired &nbsp;&nbsp;
                            </span>
                          )}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Hidden>
            </Toolbar>
          </AppBar>
          {/* <Header
                    links ={
                        <List>  
                            <ListItem>  
                                      <span> Candidate Name: </span>
                            </ListItem>
                            <ListItem>
                                    <span> Remaining Time: </span>
                            </ListItem> */}
          {/* <ListItem className={classes.listItem}>
                    <CustomDropdown
                      left
                      caret={false}
                      hoverColor="dark"
                      dropdownHeader="Profile"
                      buttonText={
                        <img
                          src={profileImage}
                          className={classes.img}
                          alt="profile"
                        />
                      }
                      buttonProps={{
                        className:
                          classes.navLink + " " + classes.imageDropdownButton,
                        color: "transparent"
                      }}
                      dropdownList={[
                        "Me",
                        "Settings and other stuff",
                        "Sign out"
                      ]}
                    />
                  </ListItem> */}
          {/* </List>
                    }
                     >    

                    </Header> */}
          <CacheImg imageURLs={this.state.imageURLs} />
          {this.questions.length > 0 && !this.state.ifImagesLoading ? (
            <React.Fragment>
              {!this.isSubmitted ? (
                <GridContainer>
                  <Grid item xs={12} className={classes.newgridsep} />
                  <GridItem xs={12} sm={this.expandfromleft.xsLeft}>
                    <GridContainer
                      style={{
                        backgroundColor: "white",
                        padding: "8px",
                      }}
                      justify="space-evenly"
                    >
                      <Subjects
                        current_question={this.state.current_question}
                        qNumPerSubject={this.state.qNumPerSubject}
                        question_number={html_current_question.question_number}
                        displayQuestion={this.displayQuestion.bind(this)}
                      />
                    </GridContainer>
                    <Grid item xs={12} className={classes.newgridsep} />
                    <GridContainer
                      id="student-math"
                      style={{ background: "white" }}
                    >
                      {val}
                    </GridContainer>

                    {/* attempt  */}
                    <GridContainer
                      //id="buttonContainer"
                      direction="row"
                      //flexWrap="wrap"
                      alignItems="center"
                      alignContent="center"
                      justify="center"
                      //   style={{
                      //     backgroundColor: "white",
                      //     textAlign: "center",
                      //     paddingLeft: "4%",
                      //   }}
                    >
                      {/* <Grid container spacing={8} > */}
                      <Grid item>
                        <Button
                          round
                          size="sm"
                          onClick={() => {
                            this.attempt("ANSWERED", this.state.ci);
                          }}
                          className={classNames(
                            classes.btnsuccess,
                            classes.btnAttempt
                          )}
                        >
                          Save & Next
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          round
                          size="sm"
                          onClick={() => {
                            this.attempt(
                              "ANSWERED_MARKED_FOR_REVIEW",
                              this.state.ci
                            );
                          }}
                          className={classNames(
                            classes.btnwarning,
                            classes.btnAttempt
                          )}
                        >
                          Save & Mark for Review
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          round
                          size="sm"
                          onClick={() => {
                            this.clear(html_current_question.question_number);
                          }}
                          className={classNames(
                            classes.btndefault,
                            classes.btnAttempt
                          )}
                        >
                          Clear Response
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          round
                          size="sm"
                          onClick={() => {
                            this.attempt(
                              "MARKED_FOR_REVIEW",
                              html_current_question.question_number,
                              false
                            );
                          }}
                          className={classNames(
                            classes.btnreview,
                            classes.btnAttempt
                          )}
                        >
                          Mark for Review & Next
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container justify="center">
                          <Grid item>
                            <Button
                              round
                              size="sm"
                              onClick={() => {
                                this.displayQuestion(this.state.ci - 1);
                              }}
                              className="btn-back"
                            >
                              BACK
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              round
                              size="sm"
                              onClick={() => {
                                this.displayQuestion(this.state.ci + 1);
                              }}
                              className="btn-next"
                            >
                              NEXT
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item>
                        <Tooltip
                          title={
                            user.level == "211210"
                              ? `You can submit the exam after 15 mins`
                              : `You can submit the exam only before ${submitBtnDisabledDuration[
                                  window.location.host
                                ] / 60} mins of the end time.`
                          }
                          placement="top"
                        >
                          <span>
                            <Button
                              disabled={
                                user.level == "211210"
                                  ? this.remainingTime / 1000 >
                                    submitBtnDisabledDuration["211210"]
                                  : this.remainingTime / 1000 >
                                    submitBtnDisabledDuration[
                                      window.location.host
                                    ]
                              }
                              round
                              size="sm"
                              onClick={() => this.submit()}
                              className={classes.btnSubmit}
                            >
                              Submit
                            </Button>
                          </span>
                        </Tooltip>
                      </Grid>
                      {/* </Grid> */}
                    </GridContainer>
                    {/* back n next  */}
                    {/* <GridContainer maxWidth="xl" style={{ background: 'white', textAlign: 'end', padding: '24px' }}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={6} md={2}>
                                                                <Button round size="sm" onClick={() => { this.displayQuestion(html_current_question.question_number - 1) }} className="btn-back">
                                                                    BACK
                                                    </Button>
                                                            </Grid>
                                                            <Grid item xs={6} md={2} >
                                                                <Button round size="sm" onClick={() => { this.displayQuestion(html_current_question.question_number + 1) }} className="btn-next">
                                                                    NEXT
                                                    </Button>
                                                            </Grid>
                                                            <Grid item xs={10} md={8} >
                                                                <Button round size="sm"  onClick={() => this.submit()} className="btn-submit">
                                                                    Submit
                                                                 </Button>
                                                            </Grid>
                                                        </Grid>
                                        </GridContainer> */}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={this.expandfromleft.xsRight}
                    style={{ display: this.expandfromleft.display }}
                  >
                    <GridContainer spacing={0}>
                      <GridItem>
                        {/* <Grid item xs={12} className={this.props.width!=="lg"?classes.newgridsep:''}></Grid> */}
                        <Typography
                          component="div"
                          style={{ backgroundColor: "f5f5f5" }}
                        >
                          <NumbersPanel
                            displayQuestion={this.displayQuestion.bind(this)}
                            questions={this.state.questions}
                            current_question={this.state.current_question}
                          >
                            {" "}
                          </NumbersPanel>
                        </Typography>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              ) : (
                <ExamSummary
                  test_type={this.props.test_type}
                  testDisplayName={this.props.displayname}
                  type={this.state.confirmationtType}
                  statusdisplay={this.statusdisplay}
                  submit={this.submit}
                >
                  {" "}
                </ExamSummary>
              )}{" "}
            </React.Fragment>
          ) : (
            <NetworkProgress />
          )}

          {/* new end  */}
        </div>
        <AlertComponent
          open={this.state.confirmOpen}
          closeAlert={this.handleCloseAlertBox}
          ifThemeRequired={false}
          alertType={this.state.alertType}
        >
          {this.state.alertText}
        </AlertComponent>
      </React.Fragment>
    );
  }
}

export default compose(
  withWidth(),
  withRouter,
  withStyles(styles)
)(CustomTypePanel);
