import React, { useEffect, useState } from "react";
import {
  Container,
  ThemeProvider,
  Typography,
  Grid,
  Select,
  MenuItem,
  useMediaQuery
} from "@material-ui/core";
import BottomNav from "../BottomNav";
import Button from "@material-ui/core/Button";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import store from "store";
import theme from "../../assets/theme";

import { fetchNotificationsList } from "../../redux/actions/notificationsAction/actionCreator";
import NetworkProgress from "../NetworkProgress";
import NotificationContent from "./NotificationContent";
import { useStyles } from "../Attendance/styles";
import ResultHeader from "../resultPage/ResultHeader";

const NotificationPage = () => {
  const notifications = useSelector((state) => state.notifications);
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [typeList, setTypeList] = useState([]);
  const [typeData, setTypeData] = useState([]);
  const [mesgType, setMesgType] = useState("All");
  const user = store.get("user");
  const { level = "", institute = "", loginIndex, userId } = user ? user : {}; // to be used in notification fetch body obj
  const { data, error, isLoading } = notifications;

  const [timeStampsStoredInStore, setTimeStampsStoredInStore] = useState();
  const [type, setType] = useState("All");

  // fetch notifications based on user's level or institute
  useEffect(() => {
    const body = { instituteIdLevelId: `${institute}_${level}`, type: type };
    if (user.accountType && user.accountType === "parent") {
      body.userId = `${institute}_${loginIndex || userId}`;
    }

    setTimeStampsStoredInStore(store.get("notificationTimestamps"));
    dispatch(fetchNotificationsList(body));
  }, [level, institute, type]);

  // set notification counter value
  useEffect(() => {
    // if timestamps are available in store then subtract from count
    const count = timeStampsStoredInStore
      ? data.filter(
          (notification) =>
            !timeStampsStoredInStore.includes(notification.created_at)
        ).length
      : data.filter((notification) =>
          // check if notification arrived today
          moment().isSame(moment(notification.created_at), "day")
        ).length;
    const getUniqueTypes = (data) => {
      const types = data.map((notification) => notification.type);
      return ["All", ...new Set(types)];
    };

    setTypeList(getUniqueTypes(data));
  }, [data, timeStampsStoredInStore]);
  useEffect(() => {
    const filteredData = data.filter(
      (notification) => notification.type === type
    );
    if (type === "All") {
      if (user && user.accountType && user.accountType === "parent") {
        setTypeData(data.filter((notification) => notification.ttl === true));
      }
      setTypeData(data);
    } else {
      if (user && user.accountType && user.accountType === "parent") {
        setTypeData(
          filteredData.filter((notification) => notification.ttl === true)
        );
      }
      setTypeData(filteredData);
    }
  }, [type, data]);
  return (
    <>
      <Container
        maxWidth={isMobile ? "400px" : "lg"}
        // className={classes.container}
        style={{
          padding: "0px !important",
          margin: "0px !important",
          display: "flex",
          justifyContent: "flex-start",
          // marginLeft: "20px",
          gap: "16px"
          // marginTop: isMobile ? "20px" : "56px",
          // marginBottom: "20px",
          // marginRight: "20px"
        }}
      >
        <Grid container spacing={1}>
          <ResultHeader testDisplayName="Notifications" path="/home-page" />
          <Grid item container xs={12} spacing={1}>
            {["All", "Unread", "Read"].map((typeValue, index) => (
              <Grid item>
                <Button
                  key={typeValue}
                  size="small"
                  variant="contained"
                  onClick={() => setMesgType(typeValue)}
                  color={typeValue === mesgType ? "primary" : ""}
                  className={
                    typeValue === mesgType
                      ? classes.activeBtn
                      : classes.inActiveBtn
                  }
                >
                  {typeValue}
                </Button>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12}>
            <Select
              value={type}
              onChange={(event) => setType(event.target.value)}
              displayEmpty
              variant="outlined"
              fullWidth
              size="small"
              style={{ marginLeft: "10px", width: "250px", height: "35px" }}
            >
              {typeList.map((typeValue) => (
                <MenuItem key={typeValue} value={typeValue}>
                  {typeValue}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={12}>
            {error && (
              <Typography variant="body2" color="error" align="center">
                Something went wrong!
              </Typography>
            )}

            {isLoading ? (
              <NetworkProgress />
            ) : data.length > 0 ? (
              <div>
                {data.map((notification, index) => (
                  <div
                    style={{
                      paddingTop: "12px"
                    }}
                  >
                    <NotificationContent key={index} content={notification} />
                  </div>
                ))}
              </div>
            ) : (
              <Typography variant="body1" color="textSecondary" align="center">
                No new notifications.
              </Typography>
            )}
          </Grid>
        </Grid>
      </Container>
      <BottomNav />
    </>
  );
};

export default NotificationPage;
