import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Grid,
  TextField,
  Divider,
  IconButton,
  makeStyles
} from "@material-ui/core";
import { Save as SaveIcon, Cancel as CancelIcon } from "@material-ui/icons";

import ReusableAccordion from "../Components/ReusableAccordion";
import useStudentData from "../../Dashboard/Components/useStudentData";
import { useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import ResultHeader from "../../resultPage/ResultHeader";
import EditSvgIcon from "../../../assets/img/navigationBarAssets/editIcon.svg";
import { set } from "date-fns";

const useStyles = makeStyles({
  table: {
    border: "none"
  },
  title: {
    color: "#2A2A2A",
    fontSize: "18px",
    lineHeight: "20px",
    fontWeight: 700,
    fontFamily: "Quicksand",
    padding: "16px 0"
  },
  icon: {
    fontSize: "25px",
    fontWeight: 700,
    color: "#2A2A2A"
  },
  keyTableCell: {
    padding: "8px 16px",
    borderBottom: "none",
    color: "#2A2A2A",
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400,
    fontFamily: "Quicksand"
    // width: "400px"
  },
  valueTableCell: {
    padding: "8px 16px",
    borderBottom: "none",
    color: "#2A2A2A",
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 700,
    fontFamily: "Quicksand"
  }
});

export default function Profile({ hideTitle }) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [studentAdmissionNo, omrNo, stuFeeData, avatar] = useStudentData();

  const [details, setDetails] = useState({});
  useEffect(() => {
    setDetails(stuFeeData);
  }, [stuFeeData]);
  const [personalDetails, setPersonalDetails] = useState({});
  const [address, setAddress] = useState({});
  useEffect(() => {
    if (details.student) {
      if (details.student.personalDetails) {
        setPersonalDetails(details.student.personalDetails);
      }
      if (details.student.address) {
        setAddress(details.student.address);
      }
    }
  }, [details]);

  const [isEditing, setIsEditing] = useState(false);
  const [editableData, setEditableData] = useState({});
  const [count, setCount] = useState(0);
  const handleChange = e => {
    const { name, value } = e.target;
    setEditableData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleEdit = () => {
    setIsEditing(true);
    setCount(1);
    setEditableData({
      fatherName: personalDetails.fatherName || "",
      fatherNumber: personalDetails.fatherNumber || "",
      fatherEmail: personalDetails.fatherEmail || "",
      fatherProfession: personalDetails.fatherProfession || "",
      fatherBloodGroup: personalDetails.fatherBloodGroup || "",
      motherName: personalDetails.motherName || "",
      motherMobile: personalDetails.motherMobile || "",
      motherEmail: personalDetails.motherEmail || "",
      motherProfession: personalDetails.motherProfession || "",
      motherBloodGroup: personalDetails.motherBloodGroup || "",
      address: address || {}
    });
  };

  const handleSave = () => {
    setIsEditing(false);
    setCount(0);
    // Save the updated data to your server or state management
    setPersonalDetails(prevDetails => ({
      ...prevDetails,
      ...editableData
    }));
    setAddress(editableData.address);
  };
  console.log(editableData);
  const handleCancel = () => {
    setIsEditing(false);
    setCount(0);
    setEditableData({});
  };

  const renderStudentSummary = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} className={classes.title}>
        Student Profile
      </Grid>
    </Grid>
  );

  const renderParentSummary = () => (
    <Grid container spacing={2}>
      <Grid item xs={8} className={classes.title}>
        Parent Profile
      </Grid>
      <Grid item xs={4} style={{ textAlign: "right" }}>
        {isEditing ? (
          <>
            <IconButton onClick={handleSave} color="primary" size="small">
              <SaveIcon />
            </IconButton>
            <IconButton onClick={handleCancel} color="secondary" size="small">
              <CancelIcon />
            </IconButton>
          </>
        ) : (
          // <IconButton color="primary" size="small">
          <img src={EditSvgIcon} alt="Edit" onClick={handleEdit} />
          // </IconButton>
        )}
      </Grid>
    </Grid>
  );

  const renderStudentDetails = () => {
    const studentData = {
      name: stuFeeData.student.fullName || "-",
      class: stuFeeData.student.class || "-",
      admissionNo: studentAdmissionNo || "-",
      batch: stuFeeData.batchName || "-",
      bloodGroup:
        (stuFeeData.student.additionalInfo &&
          stuFeeData.student.additionalInfo.bloodGroup) ||
        "-",
      mobile:
        (stuFeeData.student.personalDetails &&
          stuFeeData.student.personalDetails.fatherNumber) ||
        "-",
      email:
        (stuFeeData.student.personalDetails &&
          stuFeeData.student.personalDetails.fatherEmail) ||
        "-",
      parentName:
        (stuFeeData.student.personalDetails &&
          stuFeeData.student.personalDetails.fatherName) ||
        "-",
      contactNumber:
        (stuFeeData.student.personalDetails &&
          stuFeeData.student.personalDetails.fatherNumber) ||
        "-"
    };

    return (
      stuFeeData.student && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableContainer className={classes.table}>
              <Table>
                <TableBody>
                  {Object.entries(studentData).map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.keyTableCell}
                      >
                        {key
                          .replace(/([A-Z])/g, " $1")
                          .replace(/^./, str => str.toUpperCase())}
                      </TableCell>
                      <TableCell className={classes.valueTableCell}>
                        {value}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )
    );
  };

  const renderParentDetails = () => {
    const sections = {
      FatherDetails: {
        fatherName: personalDetails.fatherName || "-",
        fatherNumber: personalDetails.fatherNumber || "-",
        fatherEmail: personalDetails.fatherEmail || "-",
        fatherProfession: personalDetails.fatherProfession || "-",
        fatherBloodGroup: personalDetails.fatherBloodGroup || "-"
      },
      MotherDetails: {
        motherName: personalDetails.motherName || "-",
        motherMobile: personalDetails.motherMobile || "-",
        motherEmail: personalDetails.motherEmail || "-",
        motherProfession: personalDetails.motherProfession || "-",
        motherBloodGroup: personalDetails.motherBloodGroup || "-"
      },
      Address: {
        address: Object.values(address).join(", ") || "-"
      }
    };

    return (
      Object.keys(stuFeeData.student.personalDetails || {}).length !== 0 && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableContainer className={classes.table}>
              <Table>
                <TableBody>
                  {Object.entries(sections.FatherDetails).map(
                    ([key, value]) => (
                      <TableRow key={key}>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.keyTableCell}
                        >
                          {key
                            .replace(/([A-Z])/g, " $1")
                            .replace(/^./, str => str.toUpperCase())}
                        </TableCell>
                        <TableCell className={classes.valueTableCell}>
                          {isEditing ? (
                            <TextField
                              name={key}
                              value={editableData[key] || ""}
                              onChange={handleChange}
                              fullWidth
                            />
                          ) : (
                            value
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                  <TableRow>
                    <TableCell colSpan={2} className={classes.keyTableCell}>
                      <Divider />
                    </TableCell>
                  </TableRow>
                  {Object.entries(sections.MotherDetails).map(
                    ([key, value]) => (
                      <TableRow key={key}>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.keyTableCell}
                        >
                          {key
                            .replace(/([A-Z])/g, " $1")
                            .replace(/^./, str => str.toUpperCase())}
                        </TableCell>
                        <TableCell className={classes.valueTableCell}>
                          {isEditing ? (
                            <TextField
                              name={key}
                              value={editableData[key] || ""}
                              onChange={handleChange}
                              fullWidth
                            />
                          ) : (
                            value
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                  <TableRow>
                    <TableCell colSpan={2} className={classes.keyTableCell}>
                      <Divider />
                    </TableCell>
                  </TableRow>
                  {Object.entries(sections.Address).map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.keyTableCell}
                      >
                        {key
                          .replace(/([A-Z])/g, " $1")
                          .replace(/^./, str => str.toUpperCase())}
                      </TableCell>
                      <TableCell className={classes.valueTableCell}>
                        {isEditing ? (
                          <TextField
                            name={key}
                            value={editableData.address[key] || ""}
                            onChange={handleChange}
                            fullWidth
                          />
                        ) : (
                          value
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )
    );
  };

  return (
    Object.keys(stuFeeData).length !== 0 && (
      <div>
        {hideTitle ? null : (
          <ResultHeader testDisplayName="View Profile" path="/home-page" />
        )}

        <ReusableAccordion
          renderSummary={renderStudentSummary}
          renderDetails={renderStudentDetails}
          item="student"
          index={0}
          count={1}
        />
        <ReusableAccordion
          renderSummary={renderParentSummary}
          renderDetails={renderParentDetails}
          item="parent"
          index={1}
          count={count}
          
        />
      </div>
    )
  );
}
