export default {
  "5e7370083d8520a40c4ceaaa": {
    url:
      "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/bhashyam_mini.png",
    title: "BHASHYAM EDUCATIONAL INSTITUTIONS",
  },
  "5ea3572fe301133d0a73e9e0": {
    url: "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/miity_mini.png",
    title: "MITTYedu EDUCATIONAL SOCIETY",
  },
  "5e9098aaa218252d49be5a3b": {
    url: "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/sr_mini.png",
    title: "SR EDUCATIONAL INSTITUTIONS",
  },
  "61e850055876f313e30ea1de": {
    url:
      "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/agastya_mini.png",
    title: "AGASTYA JUNIOR COLLEGE",
  },
  "640053dc178e451001e83e37": {
    url: "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/onesaz_mini.png",
    title: "ONESAZ",
  },
  "5d679d49c136660a09596d85": {
    url: "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/onesaz_mini.png",
    title: "ONESAZ",
  },
  "5ee85fc60f25a93146ea0bf9": {
    url:
      "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/sriabhidakp_mini.png",
    title: "SRI ABHIDA IIT JEE FOUNDATIONS - KP",
  },
  "645b4332b98a5dc4ce54256d": {
    url:
      "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/vinners_mini.png",
    title: "VINNERS OLYMPIAD SCHOOL",
  },
  "646634c8a400ecffc1306d0d": {
    url: "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/turito_mini.png",
    title: "SREE SARASWATHI EDUCATIONAL SOCIETY",
  },
  "61e6489a9b609f2143aab796": {
    url: "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/aiitv.jpg",
    title: "SREE VENKATESWARA AIAT IIT & NEET ACADEMY",
  },
  "63a943927e91069155cc07da": {
    url: "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/edify-logo.png",
    title: "EDIFY SCHOOL",
  },
  "6422b982a32e9b7c5498b129": {
    url: "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/ligadepatil.jpg",
    title: "LigadePatil Jr College of science",
  },
  "5fd25b12ed14200ff702f738": {
    url:
      "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/tirumala_mini.png",
    title: "TIRUMALA IIT & MEDICAL ACADEMY",
  },
  "5ee85fc60f25a93146ea0bg9": {
    url:
      "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/sriabhida_mini.png",
    title: "SRI ABHIDA JUNIOR COLLEGE",
  },
  "5ee85fc60f25a93146ea0bg1": {
    url:
      "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/pinegrove_mini.jpeg",
    title: "PINEGROVE JUNIOR COLLEGE",
  },
};
// 5ee85fc60f25a93146ea0bg9
