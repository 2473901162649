import { combineReducers } from "redux";
import { EditProfileReducer } from "./editProfileReducers/EditProfileReducer";
// notifications
import { NotificationListReducer } from "./notificationReducers/NotificationListReducer";
import { AnalyticsReducer } from "./practicePageReducers/AnalyticsReducer";
import { BookmarkQuestionReducer } from "./practicePageReducers/BookmarkQuestionReducer";
// practice page reducers
import { PracticeQuestionsReducer } from "./practicePageReducers/PracticeQuestionsReducer";
import { SaveBodyReducer } from "./practicePageReducers/SaveBodyReducer";
import { SaveQueryReducer } from "./practicePageReducers/SaveQueryReducer";
import { SubmitAnswerReducer } from "./practicePageReducers/SubmitAnswerReducer";
// result page reducers
import {
  compareQuesDetailsReducer,
  rankAnalysisReducer,
  resultDetailsReducer,
} from "./resultPageReducers/resultReducer";

const root_reducer = combineReducers({
  /*practice page*/
  practiceQuestions: PracticeQuestionsReducer,
  submittedAnswer: SubmitAnswerReducer,
  searchQuery: SaveQueryReducer,
  bookmarkQuestion: BookmarkQuestionReducer,
  analyticsCurrentQuestion: AnalyticsReducer,
  savedBodyObject: SaveBodyReducer,
  /* result page*/
  allResult: resultDetailsReducer,
  rankAnalysis: rankAnalysisReducer,
  compareResult: compareQuesDetailsReducer,
  /* Notification */
  notifications: NotificationListReducer,
  /* edit profile */
  profileEdit: EditProfileReducer,
});
export default root_reducer;
