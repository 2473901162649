import React, { useEffect, useState } from "react";
import axios from "axios";
import store from "store";
import { useLocation } from "react-router-dom";
import useStudentData from "../../Dashboard/Components/useStudentData";
import TestTypeButtons from "./TestTypeButtons";
import TestDetailsAccordion from "./TestDetailsAccordion";
import institutes from "../../../common/institutes";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  List,
  ListItem,
  ListItemText,
  Card,
  TextField,
} from "@material-ui/core";
import ResultHeader from "../../resultPage/ResultHeader";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import NoResultMsg from "../../resultPage/NoResultMsg";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: "20px",
    marginRight: "20px",
  },
  card: {
    background: "#EBEFF3",
    boxShadow: `4px 4px 8px rgba(189, 194, 199, 0.75),
                    8px 8px 12px rgba(189, 194, 199, 0.25),
                   -4px -4px 8px rgba(255, 255, 255, 0.75),
                   -8px -8px 12px rgba(255, 255, 255, 0.25)`,
    borderRadius: "16px",
    maxWidth: "100%",
    overflow: "auto",
  },
  radioLabel: {
    color: "#2A2A2A",
    fontSize: "14px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px", // Tablet
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "16px", // Desktop
    },
    fontWeight: 700,
    lineHeight: "20px",
  },
}));

export default function ProgressCard() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const user = store.get("user");
  const level = query.get("level");
  const account = query.get("accountType");
  const userLevel = level || (user && user.level);
  const accountType = account || (user && user.accountType);
  const [studentAdmissionNo, omrNo, stuFeeData, avatar] = useStudentData();
  const [fetchedData, setFetchedData] = useState({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles(isMobile);
  const presentSummary = [];
  const absentSummary = [];
  const [data1, setData1] = useState([]);
  const [avgData, setAvgData] = useState([]);
  const [testTypes, setTestTypes] = useState([]);
  const [selectedTestType, setSelectedTestType] = useState(0);
  const [viewMode, setViewMode] = useState("present"); // State to manage view mode
  const instituteId = store.get("user").institute;
  const [loading, setLoading] = useState(false);
  const [absentTestNames, setAbsentTestNames] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  async function fetchData() {
    const id = store.get("user").accountType
      ? studentAdmissionNo
      : store.get("user").userId;
    const instituteId = store.get("user").institute;
    const today = new Date();
    const endDate = new Date(today);
    endDate.setMonth(today.getMonth() - 3);
    const formatDate = (date) => date.toISOString().split("T")[0];
    const startDateFormatted = formatDate(today);
    const endDateFormatted = formatDate(endDate);
    const datesParam = JSON.stringify({
      startDate: endDateFormatted,
      endDate: startDateFormatted,
    });

    try {
      const response = await axios.get(
        `https://${
          institutes[instituteId]
        }/api/analysis/studentTestRecords/${id}?dates=${encodeURIComponent(
          datesParam
        )}&instituteId=${instituteId}`
      );

      if (response.data) {
        setFetchedData(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (studentAdmissionNo.length !== 0) {
      fetchData();
    }
  }, [studentAdmissionNo]);

  useEffect(() => {
    if (fetchedData.output) {
      if (fetchedData.output.averages) {
        const selectedExamType = fetchedData.output.averages;
        setAvgData(selectedExamType);
      }
    }
  }, [fetchedData]);

  useEffect(() => {
    if (fetchedData.output) {
      if (fetchedData.output.results) {
        const finishedTests = fetchedData.output.results.filter(
          (item) => item.test_status === "FINISHED"
        );
        const absentTestNames = fetchedData.output.results.filter(
          (item) => item.test_status === "NOT_STARTED"
        );

        setAbsentTestNames(absentTestNames);

        const testTypes = fetchedData.output.results.map((test) => {
          if (
            test.test_type === "JEE MAINS" ||
            test.test_type === "JEEMAIN_2022"
          ) {
            return "JEE MAINS";
          }
          return test.test_type;
        });
        setTestTypes([...new Set(testTypes)]);

        const testNames = finishedTests.reduce((acc, obj) => {
          acc[obj.testDisplayName] = obj.test_name || "";
          return acc;
        }, {});

        setData1(finishedTests);
        const notStartedTests = fetchedData.output.results.filter(
          (item) => item.test_status === "NOT_STARTED"
        );
        notStartedTests.map((tests) => {
          const date = new Date(tests.startDateTime).getMonth();
          absentSummary.push(date);
        });

        finishedTests.map((tests) => {
          const date = new Date(tests.startDateTime).getMonth();
          presentSummary.push(date);
        });
      }
    }
  }, [fetchedData]);

  const filteredPresentTests = data1.filter((test) =>
    test.testDisplayName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredAbsentTests = absentTestNames
    .filter((test) => test.test_type === testTypes[selectedTestType])
    .filter((test) =>
      test.testDisplayName.toLowerCase().includes(searchTerm.toLowerCase())
    );

  if (
    ["211210", "2119710", "2119711"].includes(`${userLevel}`) &&
    accountType !== "parent"
  )
    return <NoResultMsg header />;

  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <ResultHeader
            testDisplayName="View Progress Card"
            path="/home-page"
          />
          {fetchedData.output && fetchedData.output.results.length === 0 && (
            <Typography
              variant="h6"
              color="error"
              align="center"
              style={{ marginTop: "100px" }}
            >
              No Test data available
            </Typography>
          )}
          <TestTypeButtons
            onSelectTestType={setSelectedTestType}
            testTypes={testTypes}
            selectedTestType={selectedTestType}
          />

          <div className={classes.root}>
            {fetchedData.output && fetchedData.output.results.length !== 0 && (
              <>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="viewMode"
                    name="viewMode"
                    value={viewMode}
                    color="primary"
                    onChange={(e) => setViewMode(e.target.value)}
                    row
                  >
                    <FormControlLabel
                      value="present"
                      control={<Radio color="primary" />}
                      label="Present"
                      className={classes.radioLabel}
                    />
                    <FormControlLabel
                      value="absent"
                      control={<Radio color="primary" />}
                      label="Absent"
                      className={classes.radioLabel}
                    />
                  </RadioGroup>
                </FormControl>
                <TextField
                  label="Search Tests"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{
                    marginBottom: "10px",
                    marginTop: "10px",
                    borderRadius: "16px",
                  }}
                />
              </>
            )}
          </div>

          {viewMode === "present" ? (
            <>
              <TestDetailsAccordion
                selectedTestType={selectedTestType}
                testDetails={filteredPresentTests}
                testTypes={testTypes}
                avgData={avgData}
              />
            </>
          ) : (
            <div className={classes.root}>
              <Card className={classes.card}>
                {viewMode === "absent" && filteredAbsentTests.length > 0 && (
                  <List>
                    <Typography variant="h6" align="center">
                      Absent Tests -{" "}
                      <b style={{ color: "#FC5132" }}>
                        {filteredAbsentTests.length}
                      </b>
                    </Typography>
                    {filteredAbsentTests.map((test, index) => (
                      <ListItem key={index}>
                        <ListItemText
                          style={{ fontSize: "12px" }}
                          primary={
                            <React.Fragment>
                              <b>{index + 1}</b>
                              {`. ${test.testDisplayName}`}
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                )}
              </Card>
            </div>
          )}
        </>
      )}
    </div>
  );
}
