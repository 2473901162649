import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
  ResponsiveContainer
} from 'recharts'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CardInsideOut from '../HomePage/Components/CardInsideOut'

const useStyles = makeStyles(theme => ({
  chartContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: "0px !important",
    margin:  "0px !important",
    width: '100%',
    padding:  "0px !important",
  }
}))

const CustomLegend = ({ payload }) => {
  const colors = {
    PHYSICS: '#46AB87',
    CHEMISTRY: '#A83B96',
    MATHEMATICS: '#3358B8',
    BIOLOGY: '#FFBB28'
  }

  // Add a check to ensure payload is defined
  if (!payload) return null

  return (
    <div style={{ textAlign: 'flex-start', marginBottom: '10px' }}>
      {payload.map(entry => (
        <span
          key={entry.value}
          style={{
            margin: '0 10px',
            display: 'inline-flex',
            alignItems: 'flex-start'
          }}
        >
          <span
            style={{
              display: 'inline-block',
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              backgroundColor: colors[entry.value] || '#000',
              marginRight: '5px'
            }}
          />
          <span>{entry.value}</span>
        </span>
      ))}
    </div>
  )
}

const StackedBarChart = ({ subjects, data }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  const gradientDefinitions = Object.keys(subjects).reduce((acc, subject) => {
    const gradientId = `color${subject}`
    const colors = {
      PHYSICS: ['#46AB87', 'rgba(106, 225, 183, 0.92)', '#46AB87'],
      CHEMISTRY: ['#A83B96', 'rgba(235, 119, 216, 0.92)', '#A83B96'],
      MATHEMATICS: ['#3358B8', 'rgba(85, 133, 255, 0.92)', '#3358B8'],
      BIOLOGY: ['#FFBB28', 'rgba(255, 221, 128, 0.92)', '#FFBB28']
    }[subject] || ['#FFBB28', 'rgba(255, 221, 128, 0.92)', '#FFBB28']

    return (
      acc +
      `
      <linearGradient id="${gradientId}" x1="0" y1="0" x2="0" y2="1">
        <stop offset="0%" stop-color="${colors[0]}" />
        <stop offset="52%" stop-color="${colors[1]}" />
        <stop offset="100%" stop-color="${colors[2]}" />
      </linearGradient>
    `
    )
  }, '')

  const transformedData =
    data &&
    data[0] !== null &&
    data
      .filter(item => item.result && item.result !== 'NA')
      .map((item, index) => {
        const result = {
          name:
            item.testDisplayName.includes('Average') ||
            item.testDisplayName.includes('Attempted Exams:')
              ? 'Avg'
              : `T${index + 1}`,
          fullName: item.testDisplayName,
          ...item.result
        }

        return result
      })

  // Limit the number of bars based on screen size
  const limitedData = transformedData.slice(
    0,
    isMobile ? 6 : isTablet ? 8 : transformedData.length
  )

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const dataIndex = transformedData.findIndex(item => item.name === label)
      const dataItem = transformedData[dataIndex]

      const colors = {
        PHYSICS: '#46AB87',
        CHEMISTRY: '#A83B96',
        MATHEMATICS: '#3358B8',
        BIOLOGY: '#FFBB28'
      }

      return (
        <div
          className='custom-tooltip'
          style={{
            padding: '10px',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: '4px'
          }}
        >
          <p
            className='label'
            style={{ fontWeight: 'bold', marginBottom: '10px' }}
          >
            {dataItem.fullName}
          </p>
          {Object.keys(subjects).map(subject => (
            <p
              key={subject}
              style={{
                margin: '5px 0',
                color: colors[subject] || '#000',
                fontWeight: '500'
              }}
            >
              {`${subject}: ${dataItem[subject] || 0}`}
            </p>
          ))}
        </div>
      )
    }

    return null
  }

  return (
    <div>
      <CustomLegend payload={Object.keys(subjects).map(subject => ({ value: subject }))} />
      <CardInsideOut className={classes.root}>
        <div className={classes.chartContainer}>
          <ResponsiveContainer width='100%' height={340}>
            <BarChart data={limitedData} style={{ marginLeft: '-30px',marginTop:"15px" }}>
              <defs dangerouslySetInnerHTML={{ __html: gradientDefinitions }} />
              <CartesianGrid strokeDasharray='3 3' vertical={false} />
              <XAxis dataKey='name' axisLine={false} tickLine={false} />
              <YAxis axisLine={false} tickLine={false} />
              <Tooltip content={<CustomTooltip />} />
              {Object.keys(subjects).map(subject => (
                <Bar key={subject} dataKey={subject} stackId='a' barSize={30}>
                  {limitedData.map((entry, index) => (
                    <Cell
                      key={`cell-${subject}-${index}`}
                      fill={`url(#color${subject})`}
                    />
                  ))}
                </Bar>
              ))}
            </BarChart>
          </ResponsiveContainer>
        </div>
      </CardInsideOut>
    </div>
  )
}

export default StackedBarChart
