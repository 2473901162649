import DateRangeIcon from '@material-ui/icons/DateRange'
import SchoolIcon from '@material-ui/icons/School'
import React, { useMemo, useState } from 'react'
import GenericButtonGroup from '../HomePage/Components/GenericButtonGroup'
import GenericDialog from '../HomePage/Components/GenericDialog'
import CreateIcon from '@material-ui/icons/Create'

const Filters = ({
  selectedDateValue,
  selectedCourseValue,
  selectedExams,
  onDateChange,
  onCourseChange,
  onExamsChange,
  exams,
  courses
}) => {
  const [open, setOpen] = useState(false)
  const [dialogConfig, setDialogConfig] = useState({ title: '', options: [] })
  const allExams = useMemo(
    () =>
      Array.isArray(exams)
        ? exams.map(e => ({ label: e.testDisplayName, value: e.test_name }))
        : [],
    [exams]
  )
  const handleDateClick = () => {
    setDialogConfig({
      title: 'Date',
      options: [
        { label: '1 Week', value: 'one week' },
        { label: '2 Weeks', value: 'two weeks' },
        { label: '1 Month', value: '1 month' },
        { label: '3 Months', value: '3 months' },
        { label: '6 Months', value: '6 months' },
        { label: '1 Year', value: '1 year' }
      ]
    })
    setOpen(true)
  }

  const handleCourseTypeClick = () => {
    setDialogConfig({
      title: 'Course Type',
      options: courses || [{ label: '', value: '' }]
    })
    setOpen(true)
  }

  const handleTestsClick = () => {
    setDialogConfig({
      title: 'Tests',
      options: allExams || []
    })
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleValueChange = value => {
    if (dialogConfig.title === 'Date') {
      onDateChange(value)
    } else if (dialogConfig.title === 'Course Type') {
      onCourseChange(value)
    } else if (dialogConfig.title === 'Tests') {
      onExamsChange(value)
    }
  }
  const buttons = [
    { label: 'Date', icon: <DateRangeIcon />, onClick: handleDateClick },
    {
      label: 'Course Type',
      icon: <SchoolIcon />,
      onClick: handleCourseTypeClick
    },
    {
      label: 'Tests',
      icon: <CreateIcon />,
      onClick: handleTestsClick
    }
  ]
  const valuesObj = {
    Date: selectedDateValue,
    'Course Type': selectedCourseValue,
    Tests: selectedExams
  }
  return (
    <div>
      <GenericButtonGroup buttons={buttons} />
      <GenericDialog
        open={open}
        onClose={handleClose}
        title={dialogConfig.title}
        options={dialogConfig.options}
        value={valuesObj[dialogConfig.title]}
        ifExams={dialogConfig.title === 'Tests'}
        onValueChange={handleValueChange}
        checked={selectedExams.length !== 0 && selectedExams}
      />
    </div>
  )
}

export default Filters
