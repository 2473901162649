import React from 'react'
import { Grid, TableContainer, Table } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ReusableAccordion from '../Components/ReusableAccordion'

const styles = theme => ({
  testName: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '20px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px' // Tablet
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '20px' // Desktop
    },
    textAlign: 'left',
    fontFamily: 'Quicksand'
  },
  verticleLine: {
    fontSize: '20px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '22px' // Tablet
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '22px' // Desktop
    },
    fontWeight: 700,
    lineHeight: '20px',
    textAlign: 'left',
    color: '#D4D4D4',
    fontFamily: 'Quicksand'
  },
  tableHead: {
    color: '#2A2A2A',
    fontSize: '14px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px' // Tablet
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '16px' // Desktop
    },
    fontWeight: 700,
    lineHeight: '20px',
    height: '10px',
    border: 'none',
    fontFamily: 'Quicksand'
  },
  tableContent: {
    color: '#737373',
    fontSize: '14px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px' // Tablet
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '16px' // Desktop
    },
    fontWeight: 400,
    lineHeight: '20px',
    height: '10px',
    border: 'none',
    fontFamily: 'Quicksand'
  },
  tableFooter: {
    color: '#2A2A2A',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    border: 'none',
    height: '10px',
    fontFamily: 'Quicksand'
  },
  tr: {
    height: '10px',
    color: '#2A2A2A',
    fontSize: '14px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px' // Tablet
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '16px' // Desktop
    },
    fontWeight: 600,
    lineHeight: '20px',
    border: 'none',
    height: '10px',
    fontFamily: 'Quicksand'
  },
  td: {
    padding: '4px 4px',
    color: '#2A2A2A',
    fontSize: '14px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px' // Tablet
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '16px' // Desktop
    },
    fontWeight: 600,
    lineHeight: '20px',
    border: 'none',
    height: '10px',
    fontFamily: 'Quicksand'
  }
})

const TestDetailsAccordion = ({
  selectedTestType,
  testDetails,
  testTypes,
  classes,
  avgData
}) => {
  const normalizeTestType = testType => {
    if (testType === 'JEEMAIN_2022') {
      return 'JEE MAINS'
    }
    return testType
  }

  const filteredTests =
    testDetails && testDetails.length !== 0
      ? testDetails.filter(
          test =>
            normalizeTestType(test.test_type) ===
            normalizeTestType(testTypes[selectedTestType])
        )
      : []

  const avg =
    avgData && avgData.length !== 0
      ? avgData
          .filter(test => test.test_type === testTypes[selectedTestType])
          .map(test => ({
            result: { ...test },
            test_type: testTypes[selectedTestType],
            testDisplayName: `Average | Attempted Exams: 
             ${(test.ATT = test.ATT.includes('|')
               ? test.ATT.replace(/\|/g, '/')
               : test.ATT)}`
          }))
      : []

  const combinedData =
    avgData && avgData.length !== 0 && testDetails && testDetails.length !== 0
      ? [...avg, ...filteredTests]
      : []
  console.log(combinedData)

  const renderSummary = (test, index, isExpanded) => (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <div
          className={`${classes.testName} ${
            index === 0 ? classes.expandedTestName : ''
          }`}
        >
          {test.testDisplayName}
        </div>
      </Grid>
      {test.result === 'NA' ? (
        <tr>
          <td colSpan='4' style={{ textAlign: 'center', color: '#FC5132' }}>
            Awaiting results
          </td>
        </tr>
      ) : null}
      {test.result !== 'NA' && (
        <Grid item xs={12}>
          <div
            className={`${classes.rankText} ${
              index === 0 ? classes.expandedRankText : ''
            }`}
          >
            <b>
              {test.result.Marks || 0}/{test.result.total || 0}
            </b>
            <b className={classes.verticleLine}> | </b>
            <b
              style={{
                color: index === 0 ? 'white' : '#A83B96'
              }}
            >
              {test.result.percentage || 0}%
            </b>
            <b className={classes.verticleLine}> | </b>
            <b>
              <span
                style={{
                  color: index === 0 ? 'white' : '#3358B8'
                }}
              >
                Rank {test.result.rank || 0}
              </span>
            </b>
          </div>
        </Grid>
      )}
    </Grid>
  )

  const renderDetails = (test, index, isExpanded) => {
    console.log(test.result)
    return (
      test.result !== 'NA' && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableContainer>
              <Table>
                <thead>
                  <tr>
                    <td className={classes.tableHead}>Subject</td>
                    <td className={classes.tableHead}>Marks</td>
                    <td className={classes.tableHead}>Rank</td>
                    <td className={classes.tableHead}>%</td>
                  </tr>
                </thead>

                <tbody>
                  {Object.keys(test.result).map(key => {
                    if (key.endsWith('Percent')) {
                      const subject = key.slice(0, -7)
                      console.log(subject)
                      return (
                        <tr key={subject}>
                          <td className={classes.tableContent}>
                            {subject.charAt(0).toUpperCase() +
                              subject.slice(1).toLowerCase()}
                          </td>
                          <td className={classes.tableContent}>
                            {test.result[subject] || 0}
                            {test.result[`${subject}Total`]
                              ? `/${test.result[`${subject}Total`]}`
                              : ''}
                          </td>
                          <td className={classes.tableContent}>
                            {test.result[`${subject}Rank`] || '-'}
                          </td>
                          <td className={classes.tableContent}>
                            {test.result[`${subject}Percent`] || '-'}
                          </td>
                        </tr>
                      )
                    }
                    return null
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td className={classes.tableFooter}>
                      <b>Total Marks</b>
                    </td>
                    <td className={classes.tableFooter}>
                      <b>
                        {test.result.Marks}/{test.result.total}
                      </b>
                    </td>
                    <td className={classes.tableFooter}>
                      <b>{test.result.rank}</b>
                    </td>
                    <td className={classes.tableFooter}>
                      <b>{test.result.percentage}</b>
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )
    )
  }

  return (
    combinedData.length !== 0 &&
    combinedData.map((item, index) => (
      <ReusableAccordion
        key={index}
        renderSummary={renderSummary}
        renderDetails={renderDetails}
        item={item}
        index={index}
        firstOneColor
      />
    ))
  )
}

export default withStyles(styles)(TestDetailsAccordion)
