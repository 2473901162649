import {
  Checkbox,
  FormGroup,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Slide from '@material-ui/core/Slide'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    position: 'absolute',
    bottom: 0,
    margin: 0,
    width: '100%',
    borderRadius: '10px 10px 0 0'
  },
  dialogContent: {
    paddingBottom: theme.spacing(2)
  },
  dialogAction: {
    justifyContent: 'space-evenly'
  }
}))

const GenericDialog = ({
  open,
  onClose,
  title,
  options,
  value,
  onValueChange,
  ifExams = false,
  checked
}) => {
  const classes = useStyles()

  const handleChange = event => {
    onValueChange(event.target.value)
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={onClose}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {ifExams ? (
          <FormGroup>
            {options.length === 0 && (
              <Typography variant='caption'>Select Course Type</Typography>
            )}
            {options.map((option, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={checked.includes(option.value)}
                    onChange={handleChange}
                    value={option.value}
                    color='primary'
                  />
                }
                label={option.label}
              />
            ))}
          </FormGroup>
        ) : (
          <RadioGroup value={value} onChange={handleChange}>
            {options.map((option, index) => (
              <FormControlLabel
                key={index}
                value={option.value}
                control={<Radio color='primary' />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        )}
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogAction }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onClose} color='primary'>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

GenericDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  value: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired
}

export default GenericDialog
