import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Lock from "@material-ui/icons/Lock";
import PlayArrow from "@material-ui/icons/PlayArrow";
import withStyles from "@material-ui/styles/withStyles";
import videoService, { getCourseSubjects } from "apis/videoService";
import moment from "moment";
import React, { Component, Fragment } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { compose } from "recompose";
import store from "store";
import theme from "../../assets/theme";
import momentTime from "../../utils/momentTime";
import ExamCard from "../../views/Portal/ExamCard";
import BottomNav from "../BottomNav";
import Header from "../Header";
import NetworkProgress from "../NetworkProgress";
import TypeHeading from "./TypeHeading";
import allSubMap from "../../utils/allSubMap";
import RedirectUnpaidPublicUsers from "../../utils/RedirectUnpaidPublicUsers";
import ResultHeader from "../resultPage/ResultHeader";
const styles = () => ({
  icons: {
    color: theme.palette.primary.main,
  },
});
class LiveClasses extends Component {
  meetingStatus = {
    live: [],
    upcoming: [],
  };
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      selectedSubject: 0,
      netReq: false,
      videos: [],
      toolTipText: null,
      meetingSub: {},
      subjects: [],
      subjectsMap: {},
    };
  }
  // check if class is live or not
  ifMeetingAllowed = (startDateTime, time_check) => {
    const startTime = startDateTime ? startDateTime : time_check;
    const allowVideo = startDateTime
      ? moment(time_check).isAfter(startTime)
      : true;
    return allowVideo;
  };

  handleTypeSelect = (event, value) => {
    this.setState({ selectedSubject: value, toolTipText: null });
  };
  // get all live and upcoming classes
  getAllLiveMeeting = async () => {
    this.setState({ netReq: true });
    let instituteId = store.get("user").institute;
    const isSR = instituteId === "5e9098aaa218252d49be5a3b";
    if (store.get("user").level) {
      instituteId = [store.get("user").institute, store.get("user").level].join(
        "_"
      );
    }
    const params = {
      instituteId: instituteId || "mockInstituteId",
    };
    if (isSR) params.branch = store.get("user").branchId;
    const meetings = await videoService.getLiveAndUpcomingClasses(params);
    if (meetings.payload) {
      meetings.payload.forEach((item) => {
        if (this.ifMeetingAllowed(item.startDateTime, item.time_check)) {
          this.meetingStatus.live.push(item);
        } else {
          this.meetingStatus.upcoming.push(item);
        }
      });
    }
    this.setState({ netReq: false });
  };
  // handle join meeting
  joinMeeting = async (meetingDetails = {}) => {
    const userObj = store.get("user");
    if (meetingDetails) {
      if (meetingDetails.url) {
        if (
          this.ifMeetingAllowed(
            meetingDetails.startDateTime,
            meetingDetails.time_check
          )
        ) {
          const val = meetingDetails.url.split("/");
          const { history } = this.props;
          if (meetingDetails.provider === "zoom") {
            const agent = navigator.userAgent;
            if (agent.includes("Android")) {
              if (window.Android)
                window.Android.startMeeting(
                  meetingDetails.url,
                  userObj.userId,
                  userObj.name
                );
            } else {
              //existing logic to start meeting on web
              history.push("/live-zoom-meeting", {
                data: meetingDetails,
              });
            }
          } else {
            history.push("/live-class", {
              live: val[0],
              chat: meetingDetails.url,
              data: meetingDetails,
            });
          }
        } else {
          this.setState({
            toolTipText: `Video is only available after ${momentTime(
              meetingDetails.startDateTime
            )}.`,
          });
        }
      }
    }
  };

  async componentDidMount() {
    let subMap = {};
    this.getAllLiveMeeting();
    const course = store.get("user").course;
    const classVal = store.get("user").classVal;
    const courseClass = classVal + "_" + course;
    const levelId = store.get("user").level;
    let instituteId = store.get("user").institute;
    if (store.get("user").level) {
      instituteId = [store.get("user").institute, store.get("user").level].join(
        "_"
      );
    }

    if (store.get("user") && course && classVal) {
      let subjects = await getCourseSubjects(courseClass);
      let todayClasses = await videoService.getMeetingDetails({
        instituteId: instituteId || "mockInstituteId",
      });
      //
      if (
        (todayClasses.data &&
          todayClasses.data.payload &&
          ([191811, 1918111, 11184, 291811].indexOf(parseInt(levelId)) < 0 &&
            (!["11", "12", "XI", "XII"].includes(classVal) &&
              ["sreducation.onesaz.com", "sr.onesaz.com"].includes(
                window.location.hostname
              )))) ||
        (window.location.hostname === "bhashyam.onesaz.com" &&
          todayClasses.data &&
          todayClasses.data.payload &&
          !todayClasses.data.payload.provider)
      ) {
        const url = todayClasses.data.payload.url;
        const val = url.split("/");
        const { history } = this.props;
        history.push("/live-class", { live: val[0], chat: url });
      }
      if (subjects) {
        subjects = subjects[courseClass] ? subjects[courseClass].subjects : [];

        if (courseClass) this.setState({ subjects: subjects });

        subjects.map((sub, index) => {
          subMap[index] = sub.value;
        });
        this.setState({ subjectsMap: subMap });

        // console.log(this.state);
      }
    } else {
      subMap = {
        0: "M",
        1: "P",
        2: "C",
        3: "G",
      };

      this.setState({
        subjects: [
          { name: "MATHEMATICS", value: "M" },
          { name: "PHYSICS", value: "P" },
          { name: "CHEMISTRY", value: "C" },
          { name: "GENERAL", value: "G" },
        ],
        subjectsMap: subMap,
      });
    }
    this.setState({ showJoin: true });
  }

  render() {
    const { classes, history } = this.props;
    console.log(this.state);
    if (
      store.get("user") &&
      store.get("user").institute == "5d72f343059f3004e1ceaa81"
    )
      history.push("/interactive-classes");

    if (RedirectUnpaidPublicUsers()) {
      return <Redirect to="/plans" />;
    }

    return (
      <Fragment>
        {/* <Header /> */}
        <br />
        <br />
        <ResultHeader testDisplayName="Live Classes" path="/live-classes" />
        <Container maxWidth="md">
          {this.state.netReq ? (
            <NetworkProgress />
          ) : (
            <Fragment>
              {this.meetingStatus.live.length === 0 &&
                this.meetingStatus.upcoming.length === 0 && (
                  <TypeHeading title="Live Classes Not Available!" />
                )}

              {this.meetingStatus.live.length > 0 && (
                <TypeHeading title="Live" variant="h5" />
              )}
              <Grid container spacing={2}>
                {this.meetingStatus.live.map((meet) => (
                  <Grid key={meet.url} item sm={6} xs={12}>
                    <ExamCard
                      title={meet.topic}
                      testDisplayName={meet.topic}
                      onClick={() => this.joinMeeting(meet)}
                      button={<PlayArrow className={classes.icons} />}
                      endDateTime={`Start time: ${moment(
                        meet.startDateTime
                      ).format("lll")}`}
                      examPaper={
                        allSubMap[meet.subject]
                          ? allSubMap[meet.subject].subject
                          : ""
                      }
                    />
                  </Grid>
                ))}
              </Grid>
              {this.meetingStatus.upcoming.length > 0 && (
                <TypeHeading title="Upcoming" variant="h5" />
              )}
              <Grid container spacing={2}>
                {this.meetingStatus.upcoming.map((meet) => (
                  <Grid key={meet.url} item xs={12} sm={6}>
                    <ExamCard
                      title={meet.topic}
                      testDisplayName={meet.topic}
                      button={<Lock className={classes.icons} />}
                      endDateTime={`Start time: ${moment(
                        meet.startDateTime
                      ).format("lll")}`}
                      examPaper={
                        allSubMap[meet.subject]
                          ? allSubMap[meet.subject].subject
                          : ""
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Fragment>
          )}
        </Container>
        <br />
        <br />
        <br />
        <br />
        <BottomNav />
      </Fragment>
    );
  }
}

export default compose(
  withStyles(styles),
  withRouter
)(LiveClasses);
